.o-modalJob {
  padding-top: rem(40);

  &_title,
  &_content {
    padding: 0 rem(64);
    @include tablet-down {
      padding: 0 rem(20);
    }
  }

  &_wrap-content {
    margin-top: rem(20);

    &.maxHeight {
      max-height: rem(390);
      overflow: auto;
      @include scroll-bars(8);
    }
  }

  &_bottom {
    box-shadow: 0 0 24px 1px $kombu-green-01;
    margin-top: rem(30);
    padding: rem(8) rem(32);
    @include tablet-down {
      padding: rem(8) rem(16);
    }

    .a-button {
      height: rem(52);
    }
  }
}

.o-popupJobDescription {
  &_bottom {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  
  &_share {
    @include adjust-flex(flex-start);

    ul {
      list-style: none;
      @include adjust-flex(flex-start);
    }

    li {
      cursor: pointer;
      display: flex;
      margin-left: rem(12);

      &:first-child {
        margin-left: rem(16);
      }
    }
  }
}

.o-popupRegister {
  &_bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.o-formRegister {
  &_row {
    display: flex;
    flex-wrap: wrap;
    margin: rem(16) rem(-16) 0;
  }

  &_col {
    flex: 0 0 50%;
    margin-top: rem(20);
    max-width: 50%;
    padding: 0 rem(16);

    @include tablet-down {
      flex: 0 0 100%;
      margin-top: rem(10);
      max-width: 100%;
    }

    &.full {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
