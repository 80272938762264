.t-jobHiring {
  &_wrap {
    @include adjust-flex(space-between, center);
    flex-wrap: wrap;
  }

  &_form {
    background-color: $white-smoke;
  }
}
