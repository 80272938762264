.t-libraryImage {
  $root: &;

  &_item {
    position: relative;

    &::before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 66.25%, rgba(0, 0, 0, 0.75) 100%);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  &_content {
    bottom: rem(16);
    padding-left: rem(16);
    padding-right: rem(16);
    position: absolute;
    width: 100%;
    z-index: 2;

    @include tablet-down {
      padding-left: rem(12);
      padding-right: rem(12);
    }
  }

  .o-container {
    @include break-min(1366px) {
      padding-left: rem(115);
      padding-right: rem(115);
    }
  }

  .swiper-slide {
    margin-bottom: auto;
    margin-right: rem(20);
    margin-top: auto;
    max-width: rem(374);

    @include tablet-down {
      max-width: rem(340);
    }

    @include mobile-down {
      max-width: rem(400);
    }

    @include small-mobile {
      margin-right: 0;
    }

    &-active {
      max-width: rem(470);

      @include tablet-down {
        max-width: rem(400);
      }

      #{$root}_item::before {
        content: '';
      }
    }

    &-next:not(.swiper-slide-active),
    &-prev:not(.swiper-slide-active) {
      max-width: rem(314);

      @include tablet-down {
        max-width: rem(280);
      }

      @include mobile-down {
        max-width: rem(400);
      }

      &::before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;

        @include mobile-down {
          content: none;
        }
      }

      #{$root}_content {
        display: none;
      }
    }

    &-prev:not(.swiper-slide-active)::before {
      background: linear-gradient(91.01deg, #fff 0.9%, rgba(255, 255, 255, 0.7602) 49.79%, rgba(255, 255, 255, 0) 99.34%);
    }

    &-next:not(.swiper-slide-active)::before {
      background: linear-gradient(269.67deg, #fff 0.32%, rgba(255, 255, 255, 0.736337) 47.43%, rgba(255, 255, 255, 0) 101.73%);
    }
  }

  .o-carousel_prev {
    left: rem(-16);

    @include desktop-down {
      left: rem(-32);
    }

    @include small-mobile {
      display: none;
    }
  }

  .o-carousel_next {
    right: rem(-16);

    @include desktop-down {
      right: rem(-32);
    }

    @include small-mobile {
      display: none;
    }
  }
}
