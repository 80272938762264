.t-introduction {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: rem(72);
  padding-top: rem(124);

  @include tablet-down {
    padding-bottom: rem(54);
    padding-top: rem(84);
  }

  @include mobile-down {
    padding-bottom: rem(32);
    padding-top: rem(52);
  }

  &_head {
    position: relative;

    .a-heading-h6 {
      @include desktop-down {
        padding-left: rem(40);
        padding-right: rem(40);
      }

      @include mobile-down {
        padding-left: rem(16);
        padding-right: rem(16);
      }
    }

    &::before,
    &::after {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      height: rem(108);
      position: absolute;
      width: rem(108);

      @include desktop-down {
        height: rem(80);
        width: rem(80);
      }

      @include tablet-down {
        height: rem(64);
        width: rem(64);
      }

      @include mobile-down {
        height: rem(48);
        width: rem(48);
      }
    }

    &::before {
      background-image: url('~assets/images/quote/open.png');
      left: rem(-108);
      top: rem(-59);

      @include desktop-down {
        left: rem(-32);
        top: rem(-42);
      }

      @include mobile-down {
        top: rem(-24);
      }

      @include small-mobile {
        left: rem(-16);
        top: rem(-36);
      }
    }

    &::after {
      background-image: url('~assets/images/quote/close.png');
      bottom: rem(-59);
      right: rem(-108);

      @include desktop-down {
        bottom: rem(-42);
        right: rem(-32);
      }

      @include mobile-down {
        bottom: rem(-24);
      }

      @include small-mobile {
        right: rem(-16);
      }
    }
  }
}
