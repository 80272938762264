.o-container {
  padding-left: rem(16);
  padding-right: rem(16);

  @include break-min(1366px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1366px;
    padding-left: rem(130);
    padding-right: rem(130);
  }

  &-noPaddingRight {
    padding-right: 0;
  }

  &-noPaddingLeft {
    padding-left: 0;
  }

  &-fullScreen {
    padding-left: 0;
    padding-right: 0;
  }

  &-fullScreenTabletUp {
    @include tablet-up {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-paddingHalf {
    @include break-min(1366px) {
      padding-left: rem(65);
      padding-right: rem(65);
    }
  }

  &-paddingBig {
    @include break-min(1366px) {
      padding-left: rem(225);
      padding-right: rem(225);
    }
  }

  &-paddingHalfLeftBig {
    @include break-min(1366px) {
      padding-left: rem(95);
    }
  }

  &-paddingHalfRightBig {
    @include break-min(1366px) {
      padding-right: rem(95);
    }
  }

  &_row {
    @include break-min($standard-viewport) {
      margin-left: rem(-16) !important;
      margin-right: rem(-16) !important;
    }
  }

  &_col {
    @include break-min($standard-viewport) {
      padding-left: rem(16) !important;
      padding-right: rem(16) !important;
    }
  }
}
