.a-button {
  $root: &;
  @include reset-button;
  @include adjust-flex;
  background: $spanish-green;
  border-radius: rem(100);
  color: $white;
  flex-direction: row;
  font-family: inherit;
  height: rem(56);
  margin: 0;
  padding: 0 rem(22);
  position: relative;
  transition: all $transition ease;

  @include tablet-down {
    height: rem(44);
  }

  @include mobile-down {
    height: rem(40);
    padding: 0 rem(16);
  }

  &-reverse {
    flex-direction: row-reverse;
  }

  span {
    font-weight: bold;
    white-space: nowrap;
    @include font-base(18, 28);

    @include tablet-down {
      @include font-base(16, 20);
    }

    @include mobile-down {
      @include font-base(14, 20);
    }
  }

  // variants
  &-secondary {
    background: $white;
    color: $spanish-green;
  }

  &-outline {
    background: $white;
    border: 1px solid $spanish-green;
    color: $spanish-green;
  }

  // sizes
  &-xs {
    height: rem(32);
  }
  
  &-sm {
    height: rem(40);

    @include tablet-down {
      height: rem(36);
    }

    span {
      @include font-base(16, 24);

      @include tablet-down {
        @include font-base(14, 20);
      }
    }
  }

  // color
  @include style-color();

  &:disabled,
  &-loading {
    pointer-events: none;
  }

  &-loading {
    position: relative;
  }

  &_loading-icon {
    border-radius: rem(100);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    @include adjust-flex;

    &-white {
      background-color: $white;
    }

    &-green {
      background-color: $spanish-green;
    }
  }

  &_icon {
    margin-left: rem(13);

    @include mobile-down {
      margin-left: rem(10);

      .a-icon {
        height: rem(13);
        width: rem(13);
      }
    }

    #{$root}-reverse & {
      margin-left: 0;
      margin-right: rem(13);

      @include mobile-down {
        margin-right: rem(10);
      }
    }
  }

  &-square {
    border-radius: rem(2);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
  }

  &-fullwidth {
    width: 100%;
  }
}
