.a-inputFile {
  $root: &;

  &_label {
    color: $davys-grey;
    display: block;
    font-weight: 400;
    margin-bottom: rem(8);
    @include font-base(12, 16);
  }

  &_instance {
    background-color: $white;
    border: 1px solid $gainsboro;
    border-radius: rem(2);
    flex-direction: column;
    padding: rem(16) rem(20);
    position: relative;
    transition: all $transition ease-in-out;
    @include adjust-flex(center, center);

    .a-text {
      transition: all $transition ease-in-out;
    }

    input {
      cursor: pointer;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &_notice {
    border: 1px dashed $gainsboro;
    border-radius: rem(2);
    box-shadow: 0 rem(2) 0 $white-0016;
    padding: rem(1) rem(8);
    transition: all $transition ease-in-out;
    @include adjust-flex(center, center);

    .a-icon {
      margin-right: rem(8);
      transition: all $transition ease-in-out;
    }
  }

  &_name {
    margin-bottom: rem(12);
    max-width: rem(320);
  }

  &-error {

    #{$root}_instance,
    #{$root}_notice {
      border-color: $sinopia;
    }
  }

  &_message {
    margin-top: rem(4);
  }
}
