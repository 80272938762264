// fonts
@font-face {
  font-family: 'Be Vietnam';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-path}BeVietnamPro-Bold.woff2') format('woff2'),
  url('#{$font-path}BeVietnamPro-Bold.woff') format('woff'),
  url('#{$font-path}BeVietnamPro-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam';
  font-style: normal;
  font-weight: 800;
  src: url('#{$font-path}BeVietnamPro-ExtraBold.woff2') format('woff2'),
  url('#{$font-path}BeVietnamPro-ExtraBold.woff') format('woff'),
  url('#{$font-path}BeVietnamPro-ExtraBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam';
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}BeVietnamPro-Medium.woff2') format('woff2'),
  url('#{$font-path}BeVietnamPro-Medium.woff') format('woff'),
  url('#{$font-path}BeVietnamPro-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}BeVietnamPro-Regular.woff2') format('woff2'),
  url('#{$font-path}BeVietnamPro-Regular.woff') format('woff'),
  url('#{$font-path}BeVietnamPro-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam';
  font-style: normal;
  font-weight: 600;
  src: url('#{$font-path}BeVietnamPro-SemiBold.woff2') format('woff2'),
  url('#{$font-path}BeVietnamPro-SemiBold.woff') format('woff'),
  url('#{$font-path}BeVietnamPro-SemiBold.ttf') format('truetype');
  font-display: swap;
}
