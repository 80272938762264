.p-detailMedia {
  &_utility {
    @include adjust-flex(space-between);
    flex-wrap: wrap;
  }

  &_description {
    * {
      max-width: 100%;
    }
  }

  &_tag {
    display: flex;

    > .a-text {
      flex-shrink: 0;
    }

    ul {
      flex-wrap: wrap;
      list-style: none;
      margin-left: rem(16);
      @include adjust-flex(flex-start);
    }
  }

  &_share {
    @include adjust-flex(flex-start);

    ul {
      list-style: none;
      @include adjust-flex(flex-start);
    }

    li {
      cursor: pointer;
      display: flex;
      margin-left: rem(12);

      &:first-child {
        margin-left: rem(16);
      }
    }
  }
}
