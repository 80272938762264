.o-jobTable {
  &_info {
    margin: 0 rem(-8);
    overflow: auto;
    padding: rem(24) rem(8) rem(12);
    @include scroll-bars(8);

    @include mobile-up {
      padding-bottom: rem(32);
      padding-top: rem(40);
    }
  }

  &_title,
  &_item {
    @include adjust-flex(space-between, flex-start);
    box-shadow: 0 0 24px 1px $kombu-green-01;

    @include mobile-up {
      padding: rem(24);
    }

    @include mobile-down {
      min-width: rem(800);
    }
  }

  &_title {
    background: $snow;
    padding: rem(24) rem(20);
  }

  &_item {
    background: $white;
    cursor: pointer;
    margin-top: rem(20);
    padding: rem(26) rem(24);
    position: relative;
    transition: all $transition linear;

    @include mobile-down {
      margin-top: rem(10);
    }

    &::before {
      background: $spanish-green;
      border-radius: 0 rem(12) rem(12) 0;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: $transition ease-in-out;
      width: rem(3);
      z-index: 1;
    }

    &:hover {
      &::before {
        opacity: 1;
      }

      box-shadow: 0 0 rem(24) rem(1) $kombu-green-03;
    }
  }

  &_office {
    flex: 0 0 20%;
  }

  &_position {
    flex: 0 0 20%;
  }

  &_part {
    flex: 0 0 15%;
  }

  &_location {
    flex: 0 0 20%;
  }

  &_date {
    flex: 0 0 15%;
  }

  &_seeMore {
    @include adjust-flex();

    @include mobile-down {
      margin-top: rem(24);
    }
  }
}
