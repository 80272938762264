@mixin size($width, $height) {
  height: rem($height);
  width: rem($width);
}

.a-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;

  &-search {
    background-image: url('~assets/icons/ic_search.svg');
  }

  &-arrowRightWhite {
    background-image: url('~assets/icons/ic_arrow_right_white.svg');
  }

  &-arrowRightGreen {
    background-image: url('~assets/icons/ic_arrow_right_green.svg');
  }

  &-mouse {
    background-image: url('~assets/icons/ic_arrow_right_green.svg');
  }

  &-arrowNextWhite {
    background-image: url('~assets/icons/ic_arrow_next_white.svg');
  }

  &-arrowPrevWhite {
    background-image: url('~assets/icons/ic_arrow_prev_white.svg');
  }

  &-arrowNextBlack {
    background-image: url('~assets/icons/ic_arrow_next_black.svg');
  }

  &-arrowPrevBlack {
    background-image: url('~assets/icons/ic_arrow_prev_black.svg');
  }

  &-mail {
    background-image: url('~assets/icons/ic_mail.svg');
  }

  &-phone {
    background-image: url('~assets/icons/ic_phone.svg');
  }

  &-closeWhite {
    background-image: url('~assets/icons/ic_close_white.svg');
  }

  &-loadingWhite {
    background-image: url('~assets/icons/ic_loading_white.svg');
  }

  &-loadingGreen {
    background-image: url('~assets/icons/ic_loading_green.svg');
  }

  &-hamburger {
    background-image: url('~assets/icons/ic_hamburger.svg');
  }

  &-arrowDownGray {
    background-image: url('~assets/icons/ic_arrow_down_gray.svg');
  }

  &-location {
    background-image: url('~assets/icons/ic_location.svg');
  }

  &-square {
    background-image: url('~assets/icons/ic_square.svg');
  }

  &-map {
    background-image: url('~assets/icons/ic_map.svg');
  }

  &-download {
    background-image: url('~assets/icons/ic_download.svg');
  }

  &-inputFileBlack {
    background-image: url('~assets/icons/ic_input_file-black.svg');
  }

  &-inputFileRed {
    background-image: url('~assets/icons/ic_input_file-red.svg');
  }

  &-downloadBlack {
    background-image: url('~assets/icons/ic_download_black.svg');
  }

  &-arrowDropDownWhite {
    background-image: url('~assets/icons/ic_arrow_drop_down-white.svg');
  }

  &-arrowRightBlack {
    background-image: url('~assets/icons/ic_arrow_right-black.svg');
  }

  &-arrowForwardLeftBlack {
    background-image: url('~assets/icons/ic_arrow_forward_left_back.svg');
  }

  &-arrowForwardRightBlack {
    background-image: url('~assets/icons/ic_arrow_forward_right_black.svg');
  }

  &-redError {
    background-image: url('~assets/icons/ic_red_error.svg');
  }

  &-greenSuccess {
    background-image: url('~assets/icons/ic_green_success.svg');
  }

  &-closeBlack {
    background-image: url('~assets/icons/ic_close_black.svg');
  }

  &-separator {
    background-image: url('~assets/icons/ic_separator.svg');
  }

  &-twitter {
    background-image: url('~assets/icons/ic_twitter.svg');
  }

  &-facebookBlack {
    background-image: url('~assets/icons/ic_facebook_black.svg');
  }

  &-link {
    background-image: url('~assets/icons/ic_link.svg');
  }

  &-closeGray {
    background-image: url('~assets/icons/ic_close_gray.svg');
  }

  &-linkedin {
    background-image: url('~assets/icons/ic_linkedin.svg');
  }

  &-play {
    background-image: url('~assets/icons/ic_play_white.svg');
  }

  &-pause {
    background-image: url('~assets/icons/ic_pause_white.svg');
  }

  &-volumeMute {
    background-image: url('~assets/icons/ic_volume_mute.svg');
  }

  &-volume {
    background-image: url('~assets/icons/ic_volume.svg');
  }

  &-clockGray {
    background-image: url('~assets/icons/ic_clock_gray.svg');
  }

  &-clockGreen {
    background-image: url('~assets/icons/ic_clock_green.svg');
  }

  &-calendarGreen {
    background-image: url('~assets/icons/ic_calendar_green.svg');
  }

  &-locationGreen {
    background-image: url('~assets/icons/ic_location_green.svg');
  }
  
}

.a-iconButton {
  background-color: unset;
  background-color: $spanish-green;
  border: unset;
  border-radius: 50%;
  padding: 0;
  @include adjust-flex;
  @include size(56, 56);

  @include tablet-down {
    @include size(44, 44);
  }

  &-white {
    background-color: $white;
  }

  &-transparent {
    background-color: transparent;
  }

  &-26 {
    @include size(26, 26);
  }

  &-30 {
    @include size(30, 30);
  }

  &-37 {
    @include size(37, 37);
  }
}
