.t-executiveBoard {
  &_wrapper {
    margin-top: rem(100);
    @include tablet-down {
      margin-top: rem(60);
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 rem(-62.5) rem(-4);
    @include desktop-down {
      margin: 0 rem(-30);
    }

    @include tablet-down {
      margin: 0 rem(-15);
    }
  }

  &_col {
    flex: 0 0 calc(100% / 3);
    margin-top: rem(52);
    max-width: calc(100% / 3);
    padding: 0 rem(62.5);
    @include desktop-down {
      margin-top: rem(40);
      padding: 0 rem(30);
    }

    @include tablet-down {
      padding: 0 rem(15);
    }

    @include small-mobile {
      flex: 0 0 100%;
      margin-top: rem(30);
      max-width: 100%;
    }
  }

}
