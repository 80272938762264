.a-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  img {
    display: none;
  }

  &-inherit {
    background-size: inherit;
  }

  &-initial {
    background-size: initial;
  }

  &-contain {
    background-size: contain;
  }

  &-1x1 {
    @include aspect-ratio;
  }

  &-4x3 {
    @include aspect-ratio(4, 3);
  }

  &-16x9 {
    @include aspect-ratio(16, 9);
  }

  &-170x96 {
    @include aspect-ratio(170, 96);
  }

  &-289x240 {
    @include aspect-ratio(289, 240);
  }

  &-436x302 {
    @include aspect-ratio(436, 302);
  }

  &-726x356 {
    @include aspect-ratio(726, 356);
  }

  &-1366x696 {
    @include aspect-ratio(1366, 696);
  }

  &-logo {
    @include aspect-ratio(172, 162);
  }

  &-285x380 {
    @include aspect-ratio(285, 380);
  }

  &-253x337 {
    @include aspect-ratio(253, 337);
  }

  &-683x384 {
    @include aspect-ratio(683, 384);
  }

  &-683x682 {
    @include aspect-ratio(683, 682);
  }

  &-189x252 {
    @include aspect-ratio(189, 252);
  }

  &-252x142 {
    @include aspect-ratio(252, 142);
  }

  &-53x33 {
    @include aspect-ratio(53, 33);
  }

  &-1366x470 {
    @include aspect-ratio(1366, 470);
  }

  &-442x261 {
    @include aspect-ratio(442, 261);
  }

  &-3x2 {
    @include aspect-ratio(3, 2);
  }

  &-632x450 {
    @include aspect-ratio(632, 450);
  }

  &-1366x460 {
    @include aspect-ratio(1366, 460);
  }

  &-3x4 {
    @include aspect-ratio(3, 4);
  }

  &-537x372 {
    @include aspect-ratio(537, 372);
  }

  &-161x91 {
    @include aspect-ratio(161, 91);
  }

  &-25x32 {
    @include aspect-ratio(25, 32);
  }

  &-470x352 {
    @include aspect-ratio(470, 352);
  }

  &-916x515 {
    @include aspect-ratio(916, 515);
  }

  &-140x95 {
    @include aspect-ratio(140, 95);
  }

  &-661x190 {
    @include aspect-ratio(661, 190);
  }

  &-450x253 {
    @include aspect-ratio(450, 253);
  }

  &-347x240 {
    @include aspect-ratio(347, 240);
  }

  &-78x52 {
    @include aspect-ratio(78, 52);
  }

  &-91x55 {
    @include aspect-ratio(91, 55);
  }

  &-165x123 {
    @include aspect-ratio(165, 123);
  }

  &-313x175 {
    @include aspect-ratio(313, 175);
  }
  
  &-820x461 {
    @include aspect-ratio(820, 461);
  }

  &-1366x668 {
    @include aspect-ratio(1366, 668);
  }
}
