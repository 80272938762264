html,
body {
  font-family: $font-be;
  overflow-x: hidden;
}

body {
  font-size: 16px;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highlight
}

button {
  &:focus {
    outline: none;
  }
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

a {
  text-decoration: none;
}

[role='button'],
button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

input {
  word-break: normal;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

.hidden-animation {
  visibility: hidden;
}

.overflow-hidden {
  height: 100vh;
}

.overflow-body {
  overflow: hidden;
  touch-action: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

.u-mt {
  $top: "4", "6", "8", "10", "12", "16", "20", "24", "25", "26", "28", "30", "32", "40", "48", "60", "64", "70";
  $top-sm: "0", "48", "54";
  $top-md: "8", "12", "16", "20", "24", "32", "40", "50", "52", "60", "72", "80", "85";
  $top-lg: "0", "8", "12", "16", "24", "30", "32", "40", "44", "48", "52", "54", "64", "72", "79", "80", "88", "105", "113", "133";
  $top-xl: "16", "24", "51";

  @each $val in $top {
    &-#{$val} {
      margin-top: #{$val}px;
    }
  }

  @each $val in $top-sm {
    &-sm-#{$val} {
      @media (min-width: 576px) {
        margin-top: #{$val}px;
      }
    }
  }

  @each $val in $top-md {
    &-md-#{$val} {
      @media (min-width: 768px) {
        margin-top: #{$val}px;
      }
    }
  }

  @each $val in $top-lg {
    &-lg-#{$val} {
      @media (min-width: 992px) {
        margin-top: #{$val}px;
      }
    }
  }

  @each $val in $top-xl {
    &-xl-#{$val} {
      @media (min-width: 1200px) {
        margin-top: #{$val}px;
      }
    }
  }
}

.u-ml {
  $left: "8", "4", "20", "16", "10";
  $left-lg: "0";
  $left-negative: "12", "10", "16";
  $left-negative-xl: "20";

  &-auto {
    margin-left: auto;
  }

  @each $val in $left {
    &-#{$val} {
      margin-left: #{$val}px;
    }
  }

  @each $val in $left-lg {
    &-lg-#{$val} {
      @media (min-width: 992px) {
        margin-left: #{$val}px;
      }
    }
  }

  @each $val in $left-negative {
    &-negative-#{$val} {
      margin-left: -#{$val}px;
    }
  }

  @each $val in $left-lg {
    &-negative-lg-#{$val} {
      @media (min-width: 1200px) {
        margin-left: -#{$val}px;
      }
    }
  }
}

.u-mr {
  $right: "4", "12";
  $right-md: "16";
  $right-lg: "0", "25";
  $right-negative: "12", "10", "16";
  $right-negative-xl: "20";

  &-auto {
    margin-left: auto;
  }

  @each $val in $right {
    &-#{$val} {
      margin-right: #{$val}px;
    }
  }

  @each $val in $right-md {
    &-md-#{$val} {
      @media (min-width: 768px) {
        margin-right: #{$val}px;
      }
    }
  }

  @each $val in $right-lg {
    &-lg-#{$val} {
      @media (min-width: 992px) {
        margin-right: #{$val}px;
      }
    }
  }

  @each $val in $right-negative {
    &-negative-#{$val} {
      margin-right: -#{$val}px;
    }
  }

  @each $val in $right-lg {
    &-negative-lg-#{$val} {
      @media (min-width: 1200px) {
        margin-right: -#{$val}px;
      }
    }
  }
}

.u-mb {
  $bottom: "16", "24", "32", "40", "48", "60", "70";
  $bottom-md: "100", "72";
  $bottom-lg: "56", "82", "100";

  @each $val in $bottom {
    &-#{$val} {
      margin-bottom: #{$val}px;
    }
  }

  @each $val in $bottom-md {
    &-md-#{$val} {
      @media (min-width: 768px) {
        margin-bottom: #{$val}px;
      }
    }
  }

  @each $val in $bottom-lg {
    &-lg-#{$val} {
      @media (min-width: 992px) {
        margin-bottom: #{$val}px;
      }
    }
  }
}

.u-pr {
  $right: 12, 16, 10, 7;
  $right-negative: 16;
  $right-sm: 16;
  $right-xl: 20;

  @each $val in $right {
    &-#{$val} {
      padding-right: #{$val}px;
    }
  }

  @each $val in $right-negative {
    &-negative-#{$val} {
      padding-right: -#{$val}px;
    }
  }

  @each $val in $right-sm {
    &-sm-#{$val} {
      @media (min-width: 576px) {
        padding-right: #{$val}px;
      }
    }
  }

  @each $val in $right-xl {
    &-xl-#{$val} {
      @media (min-width: 1200px) {
        padding-right: #{$val}px;
      }
    }
  }
}

.u-pl {
  $left: 12, 16, 10, 6, 7, 8;
  $left-negative: 16;
  $left-sm: 16;
  $left-md: 12, 16;
  $left-xl: 20;

  @each $val in $left {
    &-#{$val} {
      padding-left: #{$val}px;
    }
  }

  @each $val in $left-negative {
    &-negative-#{$val} {
      padding-left: -#{$val}px;
    }
  }

  @each $val in $left-sm {
    &-sm-#{$val} {
      @media (min-width: 576px) {
        padding-left: #{$val}px;
      }
    }
  }

  @each $val in $left-md {
    &-md-#{$val} {
      @media (min-width: 768px) {
        padding-left: #{$val}px;
      }
    }
  }

  @each $val in $left-xl {
    &-xl-#{$val} {
      @media (min-width: 1200px) {
        padding-left: #{$val}px;
      }
    }
  }
}

.u-pt {
  $top: "8", "10", "16", "20", "24", "30", "32", "80";
  $top-sm: "54", "120";
  $top-md: "16", "24", "52";
  $top-lg: "38", "52", "72", "160";

  @each $val in $top {
    &-#{$val} {
      padding-top: #{$val}px;
    }
  }

  @each $val in $top-sm {
    &-sm-#{$val} {
      @media (min-width: 576px) {
        padding-top: #{$val}px;
      }
    }
  }

  @each $val in $top-md {
    &-md-#{$val} {
      @media (min-width: 768px) {
        padding-top: #{$val}px;
      }
    }
  }

  @each $val in $top-lg {
    &-lg-#{$val} {
      @media (min-width: 992px) {
        padding-top: #{$val}px;
      }
    }
  }
}

.u-pb {
  $bottom: "8", "10", "16", "20", "24", "30", "32", "80";
  $bottom-sm: "54", "120";
  $bottom-md: "16", "24", "52";
  $bottom-lg: "38", "52", "72", "160";

  @each $val in $bottom {
    &-#{$val} {
      padding-bottom: #{$val}px;
    }
  }

  @each $val in $bottom-sm {
    &-sm-#{$val} {
      @media (min-width: 576px) {
        padding-bottom: #{$val}px;
      }
    }
  }

  @each $val in $bottom-md {
    &-md-#{$val} {
      @media (min-width: 768px) {
        padding-bottom: #{$val}px;
      }
    }
  }

  @each $val in $bottom-lg {
    &-lg-#{$val} {
      @media (min-width: 992px) {
        padding-bottom: #{$val}px;
      }
    }
  }
}