.m-cardInfoPdf {
  padding-left: rem(9);
  position: relative;

  &_time {
    background-color: $spanish-green;
    left: 0;
    padding: rem(8);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  &_content {
    align-items: center;
    background-color: $white;
    display: flex;
    filter: drop-shadow(0 0 33px rgba($black, 0.1));
    flex-wrap: wrap;
    padding: rem(24) rem(16) rem(24) rem(130);
    @include small-mobile {
      filter: drop-shadow(0 0 10px rgba($black, 0.1));
      padding: rem(16) rem(10) rem(16) rem(100);
    }
  }

  &_title {
    flex: 1;
  }

  &_pdf {
    width: rem(91);
    @include small-mobile {
      width: rem(70);
    }
  }

}
