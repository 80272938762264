.t-stock {
  .green {
    color: $spanish-green;
    font-weight: 700;
  }

  .unit {
    font-size: rem(24);
    font-weight: 400;
    @include desktop-down {
      font-size: rem(18);
    }
    @include tablet-down {
      font-size: rem(16);
    }

    @include small-mobile {
      @include font-base(14);
    }
  }

  &_content {
    background-color: $white;
    box-shadow: 0 3px 6px -4px rgba($black, 0.12), 0 6px 16px rgba($black, 0.08), 0 9px 28px 8px rgba($black, 0.05);
    padding: rem(32);
    @include tablet-down {
      padding: rem(16);
    }
  }

  &_top {
    border-bottom: 1px solid $gainsboro;
    display: flex;
    justify-content: space-between;
    padding-bottom: rem(8);
  }

  &_bottom {
    display: flex;
    flex-wrap: wrap;
    padding-top: rem(16);

    &_left {
      flex: 0 0 calc(349 / 1042  * 100%);
      margin-right: calc(32 / 1042 * 100%);
      max-width: calc(349 / 1042  * 100%);
      @include tablet-down {
        flex: 0 0 100%;
        margin-right: 0;
        max-width: 100%;
      }
    }

    &_right {
      flex: 0 0 calc(661 / 1042  * 100%);
      max-width: calc(661 / 1042  * 100%);
      @include tablet-down {
        flex: 0 0 100%;
        margin-top: rem(16);
        max-width: 100%;
      }
    }
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    margin: rem(5) rem(-20) 0;
    @include desktop-down {
      margin: rem(5) rem(-10) 0;
    }
  }

  &_block {
    flex: 0 0 50%;
    margin-top: rem(11);
    max-width: 50%;
    padding: 0 rem(20);
    @include desktop-down {
      padding: 0 rem(10);
    }
  }
}
