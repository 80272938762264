.m-introSummaryCard {
  $root: &;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &-greenAndLine,
  &-blackAndLine {
    &::before {
      background-color: $spanish-green;
      bottom: 0;
      content: '';
      height: rem(3);
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  &_img {
    margin: auto;
    max-width: rem(100);
    transform: translateY(50%);
    width: 100%;
    @include tablet-down { 
      max-width: rem(90);
    }
  }

  &_title {
    @include text-overflow(2);
  }

  &_content {
    background: $white;
    border: 1px solid $gainsboro;
    box-shadow: 0 3px 6px -4px $black-012, 0 6px 16px $black-008, 0 9px 28px 8px $black-005;
    flex: 1;

    #{$root}-blackAndLine & ,
    #{$root}-greenAndLine & {
      padding: rem(74) rem(32) rem(32) rem(32);
      @include tablet-down {
        padding: rem(60) rem(24) rem(24) rem(24);
      }
    }

    #{$root}-normal & {
      padding: rem(74) rem(24) rem(24) rem(24);
      @include tablet-down {
        padding: rem(60) rem(24) rem(24) rem(24);
      }
    }
  }

  &_description {

    #{$root}-blackAndLine & ,
    #{$root}-greenAndLine & {
      margin-top: rem(16);
      @include text-overflow(9);
    }

    li {
      display: list-item;
      margin-left: rem(20);
    }
  }
}
