.o-breadcrumb {
  @include adjust-flex(flex-start,center);
  list-style: none;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  
  &_item {
    @include adjust-flex;
    flex-shrink: 0;

    &:last-child {
      .a-icon {
        display: none;
      }

      .a-text {
        color: $black-085;
      }
    }
  }
}
