
:root {
  --animate-duration: 1.25s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.preanimate {
  opacity: 0;
}

.animate {
  -webkit-animation-duration: 1.25s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: 1.25s;
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  /* ----------------------------------------------------------------------------------------- */
  /* ----------------------------------- Attention seekers ----------------------------------- */
  /* ----------------------------------------------------------------------------------------- */

  &-pulse {
    animation-name: pulse;
    animation-timing-function: ease-in-out;
  }
  /* ----------------------------------------------------------------------------------------- */
  /* ---------------------------------- Bouncing entrances ----------------------------------- */
  /* ----------------------------------------------------------------------------------------- */
  &-bounceIn {
    animation-duration: calc(var(--animate-duration) * 0.75);
    animation-name: bounceIn;
  }

  &-bounceInDown {
    animation-name: bounceInDown;
  }

  /* ----------------------------------------------------------------------------------------- */
  /* --------------------------------- Fading entrances -------------------------------------- */
  /* ----------------------------------------------------------------------------------------- */
  &-fadeIn {
    animation-name: fadeIn;
  }

  &-fadeInLeft {
    animation-name: fadeInLeft;
  }

  &-fadeInRight {
    animation-name: fadeInRight;
  }
}
