.o-tabs {
  $root: &;

  &_labels {
    align-items: center;
    display: inline-flex;
    left: 50%;
    max-width: 100%;
    overflow-x: auto;
    position: relative;
    transform: translateX(-50%);
    @include scroll-bars(8);
  }

  &_tab {
    cursor: pointer;

    padding: 0 0 rem(8) 0;
    position: relative;

    &-mr-md {
      margin-right: rem(48);

      @include tablet-down {
        margin-right: rem(32);
      }

      @include small-mobile {
        margin-right: rem(16);
      }
    }

    &-mr-sm {
      margin-right: rem(32);

      @include tablet-down {
        margin-right: rem(24);
      }

      @include small-mobile {
        margin-right: rem(16);
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &-14x22 {
      #{$root}_label {
        @include font-base(14, 22);
      }
    }

    &::after {
      background: $spanish-green;
      bottom: 0;
      content: '';
      height: rem(2);
      left: 0;
      opacity: 0;
      position: absolute;
      transition: all $transition-long linear;
      visibility: hidden;
      width: 0;
      z-index: 1;
    }

    &:hover {
      #{$root}_label {
        color: $spanish-green;
        transition: all $transition linear;
      }
    }

    &-active {
      #{$root}_label {
        color: $spanish-green;
        font-weight: 500;
      }

      &::after {
        opacity: 1;
        visibility: visible;
        width: 100%;
      }
    }
  }

  &_label {
    color: $black-085;
    font-weight: 400;
    white-space: nowrap;
    @include font-base(22, 30);
    @include tablet-down {
      @include font-base(20, 30);
    }

    @include mobile-down {
      @include font-base(16, 24);
    }
  }

  &_panel {
    display: none;

    &-active {
      display: block;
    }
  }
}
