.a-textarea {
  $root: &;

  &_label {
    display: block;
    font-weight: 400;
    margin-bottom: rem(8);
    @include font-base(12, 16);
  }

  &_instance {
    appearance: none;
    background-color: $white;
    border: 1px solid $gainsboro;
    border-radius: rem(2);
    color: $black;
    font-family: inherit;
    margin: 0;
    outline: none;
    padding: rem(5) rem(12);
    resize: none;
    transition: all $transition linear;
    width: 100%;
    @include font-base(14, 22);

    &::placeholder {
      color: $taupe-gray1;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  &-error {
    #{$root}_instance {
      background-color: $white;
      border-color: $sinopia;
      color: $sinopia;

      &::placeholder {
        color: $sinopia;
      }
    }
  }

  &_message {
    margin-top: rem(4);
  }
}
