.m-subMenu {
  $root: &;
  box-shadow: 0 0 rem(33) $black-01;

  &_item {
    $item: &;
    border-bottom: 1px solid $black-01;
    cursor: pointer;

    &:last-child {
      border-bottom: 0;
    }
    
    &.hasSub {
      #{$item}_title::after {
        content: ''; 
      }
    }

    &.active {
      #{$item}_title {
        background-color: $spanish-green;

        .a-text {
          color: $white;
          font-weight: bold;
        }

        &::after {
          border-bottom-color: $white;
          transform: translateY(-50%) rotate(180deg);
        }
      }
      #{$item}_list {
        max-height: rem(576);
        overflow: auto;
      }
    }

    &_title {
      background-color: $white;
      display: block;
      padding: rem(13) rem(40) rem(13) rem(16);
      position: relative;
      transition: $transition linear;

      .a-text {
        transition: $transition linear;
      }

      &::after {
        border-bottom: 7px solid $dark-liver;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        height: 0;
        position: absolute;
        right: rem(8);
        top: 50%;
        transform: translateY(-50%);
        transition: $transition linear;
        width: 0;
      }
    }

    &_list {
      border-top: 0;
      max-height: 0;
      overflow: hidden;
      position: relative;
      transition: $transition-long ease-in-out;

      &::before {
        border: 1px solid $spanish-green;
        border-top: 0;
        bottom: 0;
        content: '';
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }

      &::-webkit-scrollbar {
        display: none;
      }      
    }

    &_link {
      display: block;
      padding: rem(13) rem(16) rem(13) rem(40);
      position: relative;

      .a-text {
        transition: $transition linear;
      }

      &.active,
      &:hover {
        .a-text {
          color: $spanish-green;
        }

        &::before {
          border-left-color: $spanish-green;
        }
      }

      &::before {
        border-bottom: 5px solid transparent;
        border-left: 8px solid $dark-liver;
        border-top: 5px solid transparent;
        content: '';
        height: 0;
        left: rem(18);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: $transition linear;
        width: 0;
      }
    }

  }
}
