.t-categoryTree {
  padding-bottom: rem(72);
  padding-top: rem(72);

  @include tablet-down {
    padding-bottom: rem(54);
    padding-top: rem(54);
  }

  @include mobile-down {
    padding-bottom: rem(24);
    padding-top: rem(24);
  }

  &_info {
    margin: 0 auto;
    max-width: rem(726);
  }
  
  &_description {
    margin-top: rem(16);
    
    @include mobile-down {
      margin-top: rem(8);
    }
  }

  &_list {
    margin-top: rem(16);
  }

  &_row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-8);
  }

  &_col {
    flex: 0 0 calc(100% / 3);
    margin-top: rem(16);
    max-width: calc(100% / 3);
    padding: 0 rem(8);

    @include tablet-down {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include small-mobile {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &_btn {
    display: flex;
    justify-content: center;
    margin-top: rem(32);
    @include small-mobile {
      margin-top: rem(24);
    }
  }
}
