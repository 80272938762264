.t-investmentRelation {
  border: rem(1) solid $platinum6;
  box-shadow: 0 0 33px rgba($black, 0.1);
  padding: 0 rem(16);

  &-videos {
    .m-playerCard_content {
      overflow: hidden;
    }

    .m-playerCard_bg {
      transition: all $transition ease-in-out;

      &:hover {
        transform: scale(1.2);
      }
    }

    .m-playerCard_play {
      height: rem(32);
      width: rem(25);
    }

    .m-playerCard_description {
      color: $eerie-black;
      font-weight: 600;
      margin-top: 0;
      @include font-base(14, 24);
    }
  }

  &-images {
    .m-investmentImageCard {
      overflow: hidden;
      position: relative;

      .a-image {
        transition: $transition ease-in-out;
      }

      &:hover {
        .a-image {
          transform: scale(1.2);
        }
      }

      &_thumbnail {
        position: relative;

        &::before {
          background: rgba($black, 0.5);
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          transition: all $transition ease-in-out;
          width: 100%;
          z-index: 1;
        }
      }

      &_content {
        bottom: 0;
        padding: rem(8);
        position: absolute;
        transition: all $transition ease-in-out;
        z-index: 1;
      }
    }

    .a-icon-arrowNextWhite,
    .a-icon-arrowPrevWhite {
      max-height: rem(16);
      max-width: rem(16);
    }

    .o-carousel_next {
      background: rgba($black, 0.5);
      left: calc(100% - 24px);
    }

    .o-carousel_prev {
      background: rgba($black, 0.5);
      left: rem(8);
    }

    .o-carousel_pagination {
      display: none;
    }
  }

  &_title {
    padding: rem(16) 0;
    position: relative;

    &::before {
      background: $spanish-green;
      bottom: 0;
      content: '';
      height: rem(3);
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.gap-24 {
      gap: rem(24);
    }
  }

  &_contacts {
    display: flex;
    flex-direction: column;
  }

  &_contactItem {
    &_icon {
      flex-shrink: 0;
      width: rem(18);
    }
  }
}
