.p-search {
  padding: rem(44) 0 rem(103);
  @include tablet-down {
    padding: rem(32) 0 rem(50);
  }
  @include mobile-down {
    padding: rem(24) 0 rem(32);
  }

  &_input {
    margin: rem(32) auto auto;
    max-width: rem(740);
  }

  &_search-input {
    background-color: $white;
    display: flex;

    input {
      border: 1px solid $gainsboro;
      color: $arsenic;
      flex-grow: 1;
      outline: none;
      padding: rem(5) rem(12);
      width: 100%;
      @include font-base(14,22);
    }

    button {
      @include reset-button;
      @include adjust-flex;
      background-color: transparent;
      border: 1px solid $gainsboro;
      border-left: 0;
      cursor: pointer;
      flex-shrink: 0;
      min-width: rem(32);
    }
  }

  &_textResult {
    margin-top: rem(16);
  }

  &_tabs {
    @include adjust-flex(space-between, center);
    @include mobile-down {
      flex-direction: column;

      &_pulldown {
        margin-top: rem(16);
      }
    }
  }

  &_row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-16);

    @include desktop-down {
      padding: 0 rem(10);
    }
  }

  &_col {
    flex: 0 0 calc(100% / 3);
    margin-top: rem(16);
    max-width: calc(100% / 3);
    padding: 0 rem(16);

    @include desktop-down {
      padding: 0 rem(10);
    }

    @include tablet-down {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include small-mobile {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

}

.m-cardSearch {
  display: flex;
  flex-direction: column;
  height: 100%;

  &_title {
    border: 1px solid $gainsboro;
    border-top: 0;
    flex-grow: 1;
    padding: rem(16) rem(24) rem(24);

    @include tablet-down {
      padding: rem(16);
    }

    .a-text {
      @include text-overflow(4);
    }
   
  }
}
