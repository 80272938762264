.o-pagination {
  @include adjust-flex;

  .main {
    align-items: center;
    display: flex;
    list-style: none;
  }

  .page {
    &.disabled {
      pointer-events: none;
    }
    
    &:not(.disabled) {
      .link-previous,
      .link-next {
        border: 1px solid $gainsboro;
        border-radius: 2px;
      }
    }

    &.active-page {
      .link-page {
        background-color: $white;
        border: 1px solid $spanish-green;
        border-radius: 2px;
        color: $spanish-green;
      }
    }

    &:hover:not(.disabled) {
      .link-previous,
      .link-next {
        border-color: $spanish-green;
      }

      .link-page {
        color: $spanish-green;
      }
    }
  }

  .link-page {
    color: $black-085;
    height: rem(24);
    min-width: rem(24);
    padding: 0 rem(3);
    transform: $transition linear;
    @include adjust-flex;
    @include font-base(14,22);
  }
}
