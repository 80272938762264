.t-mediaHome {
  $root: &;
  background-color: $white;
  padding-bottom: rem(72);
  padding-top: rem(72);

  @include tablet-down {
    padding-bottom: rem(54);
    padding-top: rem(54);
  }

  @include mobile-down {
    padding-bottom: rem(24);
    padding-top: rem(24);
  }

}

.mediaCarousel {
  $root: &;

  .swiper-slide {
    @include tablet-up {
      height: auto;
    }
    
    .o-carousel_content {
      padding: rem(10) 0;
    }

    #{$root}_card {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-start;
     
      &_sub {
        display: block;
        max-width: rem(289);
        position: relative;
        width: 100%;
        @include desktop-down {
          max-width: rem(200);
        }
        @include tablet-down {
          display: none;
        }

        &::before {
          background: $white-linear1;
          bottom: 0;
          content: '';
          left: 0;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
        }
      }
      
      &_main {
        display: none;
        @include tablet-down {
          display: block;
        }
      }
    }

    &-prev {
      #{$root}_card {
        justify-content: flex-end;
      }
    }

    &-next {
      #{$root}_card_sub::before {
        transform: matrix(-1, 0, 0, 1, 0, 0);
      }
    }

    &-active {
      #{$root}_card { 
        &_sub {
          display: none;
        }

        &_main {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
