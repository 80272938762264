.o-popupGallery {
  $root: &;
  margin: 0 auto;
  max-width: rem(916);
  
  @include desktop {
    max-width: rem(700);
  }

  &_main {
    .o-carousel-navigationOutside {
      .o-carousel_next {
        @include break-min(1366px) {
          right: rem(-126);
        }
      }

      .o-carousel_prev {
        @include break-min(1366px) {
          left: rem(-126);
        }
      }
    }
  }

  &_thumb {
    margin-top: rem(16);
    @include small-mobile {
      margin-top: rem(10);
    }

    &-item {
      cursor: pointer;
      position: relative;

      &::before {
        background-color: $white-06;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }

    .swiper-slide-thumb-active {
      #{$root}_thumb-item::before {
        display: none;
      }
    }
  }

  &_pagination {
    display: flex;
    font-weight: bold;
    justify-content: center;
    margin-bottom: rem(32);
    @include font-base(16,24);

    @include desktop-down {
      margin-bottom: rem(16);
    }

    @include tablet-down {
      margin-bottom: rem(10);
      @include font-base(14,20);
    }
  }
}
