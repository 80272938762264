.m-pulldown {
  $root: &;
  position: relative;

  &_label {
    color: $raisin-black;
    margin-bottom: rem(8);
    @include font-base(14, 22);
    @include adjust-flex(flex-start, center);

    @include mobile-down {
      @include font-base(12, 20);
    }

    &-required {
      color: $red-orange;
      margin-left: rem(4);
    }
  }

  &_header {
    align-items: center;
    background: $white;
    border: 1px solid $gainsboro;
    border-radius: rem(2);
    color: $raisin-black;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    z-index: 1;

    @include font-base(14, 22);

    &_content {
      align-items: center;
      display: flex;
      flex-grow: 1;
      height: 100%;
      overflow: hidden;
      padding: rem(5) rem(12);
      text-overflow: ellipsis;
      width: calc(100% - 26px);

      span {
        @include text-overflow(1);
      }
    }
  }

  &_placeholder {
    color: $raisin-black;
  }

  &_search {
    background: transparent;
    border: 0;
    color: $davys-grey;
    font-size: rem(14);
    height: 100%;
    left: 0;
    padding: rem(7.2) rem(12);
    position: absolute;
    top: 0;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &_fn {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    margin-right: rem(10);
  }

  &_arrow { 
    display: block;
    height: rem(14);
    position: relative;
    width: rem(14);

    &::after {
      background: url('~assets/icons/ic_arrow_triangle_down.svg') no-repeat;
      background-position: center;
      background-size: rem(14);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: $transition;
      width: 100%;
    }

    &.opened {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &_clear {
    margin-right: rem(3);
    padding: rem(6);
    position: relative;

    &::after {
      background: $gray-x11;
      content: '';
      height: rem(14);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
    }
  }

  &_wrapper {
    background: $white;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  &_list {
    border: 1px solid $gainsboro;
    color: $davys-grey;
    margin: 0;
    max-height: rem(161);
    overflow-y: auto;
    padding: 0;
    @include font-base(14, 21);
    @include scroll-bars(8, $gainsboro, transparent, 4, 2);
  }

  &_item {
    cursor: pointer;
    list-style: none;
    padding: rem(5) rem(12);
    transition: all $transition linear;
    white-space: normal;

    &:first-child {
      margin-top: rem(4);
    }

    &:hover {
      background-color: $spanish-green;
      color: $white;
    }

    &.none {
      color: $gray-x11;
      text-align: center;
    }
  }

  &-error {
    #{$root}_header {
      border-color: $pigment;
    }

    #{$root}_label {
      color: $pigment;
    }

    #{$root}_error {
      @include font-base(12, 18);
      color: $fire-engine-red;
      font-weight: 500;
      margin-top: rem(4);
      opacity: 0.8;
    }
  }
}
