.t-activityBanner {
  $root: &;
  // max-width: rem(1366);
  // margin: 0 auto;

  &_wrap {
    position: relative;
    #{$root}-hasList & {
      padding-bottom: calc(378 / 1366 * 100%);
    }
    #{$root}-normal & {
      padding-bottom: calc(570 / 1366 * 100%);
    }
  }

  &_description {
    margin-top: rem(16);
  }

  &_sub {
    margin-top: rem(16);
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 rem(-15.5);
    @include tablet-down {
      margin: 0 rem(-10);
    }
  }

  &_item {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
    padding: 0 rem(15.5);

    @include tablet-down {
      padding: 0 rem(10);
    }

    @include mobile-down {
      flex: 0 0 100%;
      margin-top: rem(-16);
      max-width: 100%;
    }
  }

  &_image {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;

    &::before {
      background: url('~assets/images/activityBanner/cloud_top.png') no-repeat center / cover;
      content: '';
      left: 0;
      padding-bottom: 48.5%;
      pointer-events: none;
      position: absolute;
      top: 0;
      transform: translateY(-25%);
      width: 100%;
      z-index: 1;
      #{$root}-hideCloud & {
        display: none;
      }
    }

    &::after {
      background: url('~assets/images/activityBanner/cloud_bottom.png') no-repeat center / cover;
      bottom: rem(-5);
      content: '';
      left: 0;
      padding-bottom: 54.6%;
      pointer-events: none;
      position: absolute;
      width: 100%;
      z-index: 1;
      #{$root}-deepCloud & {
        background: url('~assets/images/activityBanner/cloud_bottom_1.png') no-repeat center / cover;
      }
      #{$root}-hideCloud & {
        display: none;
      }
      #{$root}-hideCloudBottom & {
        display: none;
      }
    }
  }

  &-hideCloud {
    #{$root}_image .a-image::before {
      padding-bottom: 60%;
    }
  }
}
