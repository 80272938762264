.t-mainLayout {
  margin-top: rem($height-header-desktop);
  min-height: 70vh;
  @include tablet-down {
    margin-top: rem($height-header-tablet-down);
  }

  &-home {
    min-height: calc(100vh - #{$height-header-desktop}px);
    @include tablet-down {
      min-height: calc(100vh - #{$height-header-tablet-down}px);
    }
  }
}