.t-activityHome {
  $root: &;

  &_content {
    margin-top: rem(32);
    position: relative;
    @include mobile-down {
      margin-top: rem(16);
    }
    @include tablet-up {
      height: 80vh;
      max-height: rem(900);
      min-height: rem(600);
    }
  }

  .line_straight {
    background-color: $white;
    height: 0;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 2px;
    z-index: 3;

    &.active {
      height: calc(35% + 30px);
      transition: 0.8s linear;
    }
    @include tablet-down {
      display: none;
    }
  }

  .line_horizontal {
    background-color: $white;
    height: 2px;
    position: absolute;
    top: calc(35% + 30px);
    transform: translateX(-50%);
    width: 0;
    z-index: 3;

    &.first {
      left: 25%;
    }

    &.second {
      left: 50%;
    }

    &.third {
      left: 75%;
    }

    &.active {
      transition: 0.5s 0.3s ease-in-out;
      width: calc(25% - 100px);
    }
    @include tablet-down {
      display: none;
    }
  }

  &_item {
    height: 100%;
    position: absolute;
    width: 100%;
    @include tablet-down {
      height: 80vh;
      min-height: rem(500);
      position: relative;
    }

    &.first {
      #{$root}_layer,
      #{$root}_bg {
        left: 0;
      }
      #{$root}_info {
        left: 12.5%;
        @include tablet-down {
          left: 50%;
        }
      }
    }

    &.second {
      #{$root}_layer,
      #{$root}_bg {
        left: 25%;
        @include tablet-down {
          left: 0;
        }
      }
      #{$root}_info {
        left: 37.5%;
        @include tablet-down {
          left: 50%;
        }
      }
    }

    &.third {
      #{$root}_layer,
      #{$root}_bg {
        left: 50%;
        @include tablet-down {
          left: 0;
        }
      }
      #{$root}_info {
        left: 62.5%;
        @include tablet-down {
          left: 50%;
        }
      }
    }

    &.fourth {
      #{$root}_layer,
      #{$root}_bg {
        left: 75%;
        @include tablet-down {
          left: 0;
        }
      }
      #{$root}_info {
        left: 87.5%;
        @include tablet-down {
          left: 50%;
        }
      }
    }
  }

  &_layer {
    background-color: rgba($black, 0.3);
    height: 100%;
    position: absolute;
    width: 25%;
    z-index: 2;
    @include tablet-down {
      width: 100%;
    }
  }

  &_info {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    max-width: 16%;
    min-height: rem(200);
    position: absolute;
    top: 35%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 3;

    @include tablet-down {
      max-width: 80%;
    }

    &:hover {
      @include tablet-up {
        z-index: 12;

        #{$root}_text {
          opacity: 1;

          &.first,
          &.second {
            left: 140%;
          }

          &.third,
          &.fourth {
            right: 140%;
          }
        }

        #{$root}_button {
          opacity: 1;
          transform: translate(-50%, 100%);
        }

        & ~ #{$root}_layer {
          z-index: 11;
        }

        & ~ #{$root}_bg {
          left: 0;
          width: 100%;
          z-index: 10;
        }
      }
    }
  }

  &_circle {
    position: absolute;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    top: rem(-20);
  
    &.active {
      stroke-dashoffset: 0;
      transition: 2s 1s linear;
      @include tablet-down {
        stroke-dashoffset: 0;
        transition: 0.8s linear;
      }
    }
  }

  &_icon {
    height: rem(60);
    opacity: 0;
    transition: 1s 1.2s ease-in-out;
    width: rem(60);
    @include tablet-down {
      transition: 0.6s 0.8s ease-in-out;
    }

    &.active {
      opacity: 1;
    }
  }

  &_title {
    margin-top: rem(45);
    opacity: 0;
    transition: 1s 2s ease-in-out;
    @include text-overflow(3);

    &.active {
      opacity: 1;
    }
    @include tablet-down {
      margin-top: rem(35);
      transition: 0.6s 0.9s ease-in-out;
    }

    .a-text {
      @include tablet-down {
        @include font-base(24,32);
      }
      @include mobile-down {
        @include font-base(20,28);
      }
    }
  }

  &_button {
    bottom: 0;
    left: 50%;
    opacity: 0;
    position: absolute;
    transform: translate(-50%, 30%);
    transition: 0.4s linear;
    @include tablet-down {
      display: block;
      margin-top: 0;
      position: initial;
      transform: initial;
    }

    &.active {
      @include tablet-down {
        margin-top: rem(20);
        opacity: 1;
        transition: 0.6s 0.9s ease-in-out;
      }
    }
  }

  &_text {
    opacity: 0;
    position: absolute;
    top: 50%;
    transition: 0.4s linear;
    width: 180%;
    @include text-overflow(5);
    @include tablet-down {
      margin-top: rem(10);
      position: initial;
      text-align: center;
      transition: initial;
      width: 100%;
    }

    &.active {
      @include tablet-down {
        opacity: 1;
        transition: 0.6s 0.9s ease-in-out;
      }
    }

    &.first,
    &.second {
      left: 70%;
    }

    &.third,
    &.fourth {
      right: 70%;

      .a-text {
        text-align: right;
        @include tablet-down {
          text-align: center;
        }
      }
    }
  }

  &_bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: absolute;
    transition: all 0.5s ease-in-out;
    width: 25%;
    z-index: 1;

    @include tablet-down {
      width: 100%;
    }

    img {
      display: none;
    }
  }

}
