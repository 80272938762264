.m-activityInfo {
  $root: &;

  &_flex {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: rem(17);
    padding-top: rem(17);
    position: relative;

    @include mobile-down {
      padding-top: 0;
    }
  }

  .divider {
    background: $spanish-green;
    position: absolute;

    &.head {
      height: rem(6);
      max-width: rem(223);
      right: 0;
      top: 0;
      width: 100%;

      @include desktop-down {
        max-width: rem(180);
      }

      @include tablet-down {
        max-width: rem(150);
      }

      @include mobile-down {
        height: 100%;
        left: 0;
        max-width: rem(6);
        right: auto;
      }
    }

    &.vertical {
      height: 100%;
      right: 0;
      top: 0;
      width: rem(6);
    }

    &.bottom {
      bottom: 0;
      height: rem(6);
      max-width: rem(450);
      right: 0;
      width: 100%;

      @include desktop-down {
        max-width: rem(380);
      }

      @include tablet-down {
        max-width: rem(330);
      }

      @include mobile-down {
        max-width: 100%;
      }
    }
  }

  &_thumbnail {
    flex: 0 0 calc(632 / 1126 * 100%);

    @include tablet-down {
      flex: 0 0 50%;
    }

    @include mobile-down {
      flex: 0 0 100%;
    }
  }

  &_content {
    flex: 0 0 calc((1126 - 632) / 1126 * 100%);
    padding-left: rem(60);
    padding-right: rem(27);

    @include desktop-down {
      padding-left: rem(48);
      padding-right: rem(24);
    }

    @include tablet-down {
      flex: 0 0 50%;
      padding-left: rem(24);
      padding-right: rem(16);
    }

    @include mobile-down {
      flex: 0 0 100%;
      padding-left: rem(16);
      padding-top: rem(12);
    }
  }

  &_title {
    @include desktop-up {
      max-width: rem(272);
    }

    @include tablet-up {
      max-width: rem(280);
    }
  }

  &_description {
    margin-top: rem(24);

    @include tablet-down {
      margin-top: rem(8);
    }

    @include mobile-down {
      margin-top: rem(4);
    }
  }

  &-reverse {
    #{$root}_flex {
      flex-direction: row-reverse;
    }

    #{$root}_content {
      padding-left: rem(22);
      padding-right: rem(65);

      @include desktop-down {
        padding-left: rem(24);
        padding-right: rem(48);
      }

      @include tablet-down {
        padding-left: rem(16);
        padding-right: rem(24);
      }

      @include mobile-down {
        padding-right: rem(16);
      }
    }

    .divider {
      &.head {
        left: 0;
        right: auto;

        @include mobile-down {
          left: auto;
          right: 0;
        }
      }

      &.vertical {
        left: 0;
        right: auto;
      }

      &.bottom {
        left: 0;
        right: auto;
      }
    }
  }
}
