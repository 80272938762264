.a-loadingPage {
  background-color: $white;
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: $z-loading;
  @include adjust-flex(center, center);

  @keyframes scale {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.5);
    }

    100% {
      transform: scale(1);
    }
  }

  &_thumbnail {
    animation: scale 5s linear infinite;
    max-width: rem(74);
    width: 100%;
    
    img {
      height: auto;
      width: 100%;
    }
  }
}
