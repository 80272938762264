.t-newsTemplate {
  .divider {
    background-color: $gainsboro;
    height: 1px;
    width: 100%;
  }

  &_main {
    .m-mediaCard_title .a-heading {
      @include tablet-up {
        @include font-base(24, 36);
      }
    }

    .m-mediaCard_description .a-text {
      @include font-base(16, 24);

      @include tablet-down {
        @include font-base(14, 20);
      }
    }

    .m-mediaCard_img {
      @include mobile-up {
        flex: 0 0 calc(721 / 1091 * 100%);
        max-width: calc(721 / 1091 * 100%);
        padding-bottom: 0;
      }
      
    }

    .m-mediaCard_content {
      @include mobile-up {
        flex: 0 0 calc(370 / 1091 * 100%);
        max-width: calc(370 / 1091 * 100%);
      }
    }
  }
}
