.m-mediaCard {
  display: flex;
  flex-wrap: wrap;

  &_img {
    flex: 0 0 60%;
    max-width: 60%;
    padding: rem(27) 0;
    position: relative;

    @include mobile-down {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
    }

    &::before {
      border-color: transparent $ash-grey $ash-grey transparent;
      border-style: solid;
      border-width: rem(14);
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
      @include mobile-down {
        display: none;
      }
    }
  }

  &_content {
    box-shadow: 0 0 rem(24) $silver-05;
    display: flex;
    flex: 0 0 40%;
    flex-direction: column;
    justify-content: space-between;
    max-width: 40%;
    padding: rem(27) rem(19) rem(27) rem(26);

    @include desktop-down { 
      padding: rem(8) rem(16);
    }

    @include mobile-down {
      flex: 0 0 100%;
      max-width: 100%;
      padding: rem(16);
    }
  }

  &_info {
    flex-grow: 1;
  }

  &_title {
    @include text-overflow(4);
    @include desktop {
      @include text-overflow(3);
    }
  }

  &_day {
    margin-top: rem(13);
  }

  &_description {
    margin-top: rem(9);
    @include text-overflow(5);
    @include desktop {
      @include text-overflow(4);
    }
  }

  &_button {
    margin-top: rem(17);
    @include mobile-down {
      margin-top: rem(10);
    }
  }
}
