.t-fieldActivityHome {
  $root: &;

  &_carousel {
    margin-top: rem(32);
    @include mobile-down {
      margin-top: rem(16);
    }
  }

  &_item {
    position: relative;

    &-1 {
      animation-delay: 0.25s;
      #{$root}_title {
        max-width: rem(144);
      }
      #{$root}_description {
        @include desktop-up {
          left: calc(100% + 62px);
        }
      }
      #{$root}-1 & {
        #{$root}_description, #{$root}_button {
          opacity: 1;
          visibility: visible;
          @include desktop-down {
            max-height: rem(999);
          }
        }
      }
    }

    &-2 {
      animation-delay: 0.35s;
      #{$root}_title {
        max-width: rem(160);
      }
      #{$root}_description {
        @include desktop-up {
          left: calc(100% + 62px);
        }
      }
      #{$root}-2 & {
        #{$root}_description, #{$root}_button {
          opacity: 1;
          visibility: visible;
          @include desktop-down {
            max-height: rem(999);
          }
        }
        #{$root}_inner {
          @include desktop-up {
            transform: translateX(calc(100vw / 4));
          }
        }
      }
    }

    &-3 {
      animation-delay: 0.45s;
      #{$root}_title {
        max-width: rem(192);
      }
      #{$root}_description {
        @include desktop-up {
          right: calc(100% + 62px);
          text-align: right;
        }
      }
      #{$root}-3 & {
        #{$root}_description, #{$root}_button {
          opacity: 1;
          visibility: visible;
          @include desktop-down {
            max-height: rem(999);
          }
        }

        #{$root}_inner {
          @include desktop-up {
            transform: translateX(calc(100vw / 2));
          }
        }
      }
    }

    &-4 {
      animation-delay: 0.55s;
      #{$root}_title {
        max-width: rem(240);
      }
      #{$root}_description {
        @include desktop-up {
          right: calc(100% + 62px);
          text-align: right;
        }
      }
      #{$root}-4 & {
        #{$root}_description, #{$root}_button {
          opacity: 1;
          visibility: visible;
          @include desktop-down {
            max-height: rem(999);
          }
        }

        #{$root}_inner {
          @include desktop-up {
            transform: translateX(calc(100vw - 342px));
          }

          @include max-desktop {
            transform: translateX(calc(100vw - 480px));
          }
        }
      }
    }
  }

  &_inner {
    align-items: center;
    background-color: $black-012;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    min-height: rem(600);
    position: relative;
    transition: max-width $transition ease-in-out;

    @include tablet-down {
      min-height: rem(460);
    }
  }

  &_thumbnail {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &_icon {
    margin-left: auto;
    margin-right: auto;
    width: rem(60);
  }

  &_title {
    margin: rem(16) auto 0;
    position: relative;
  }

  &_button {
    margin: rem(32) auto 0;
    max-width: rem(200);
    opacity: 0;
    transition: all $transition ease-in-out;
    visibility: hidden;
    @include desktop-down {
      max-height: 0;
    }
  }

  &_description {
    max-width: rem(460);
    opacity: 0;
    position: absolute;
    transition: all $transition ease-in-out;
    visibility: hidden;
    width: 100%;

    @include desktop-down {
      margin-top: rem(24);
      max-height: 0;
      max-width: 80%;
      position: relative;
      text-align: center;
    }
  }

  &-hover {
    .swiper-slide {
      @include desktop-up {
        pointer-events: none;
        width: 0 !important;
      }
    }
    #{$root}_inner {
      @include max-desktop {
        max-width: rem(480);
      }
      @include max-desktop-down {
        max-width: rem(342);
      }
      @include desktop-down {
        max-width: 100%;
      }
    }
  }

  .o-carousel {
    .swiper-slide {
      transition: all $transition-long ease-in-out;
    }

    .slide-custom-active {
      @include desktop-up {
        pointer-events: all;
        width: 100% !important;
      }
    }
  }
}
