.o-popupVideo {
  margin: 0 auto;
  max-width: rem(1090);

  &_video {
    padding-bottom: calc(613 / 1090 * 100%);
    position: relative;

    .player {
      position: absolute;
      width: 100%;
      height: 100%;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}
