.o-timelineslider {
  $root: &;
  $root-item: #{$root}_item;
  $animation: 0.3s ease-in-out;
  margin: 0 auto;
  // NOTES: Set max-width to control content's width equal to Container (1126px)
  max-width: 1251.14px;
  position: relative;
  width: 100%;

  // &_content {
  //   margin: 0 auto;
  //   width: calc(100% - (100%/4));

  //   // NOTES: Medias depend on Breakpoints
  //   @media(min-width: 1200px) {
  //     width: calc(100% - (100%/10));
  //   }

  //   @media(min-width: 768px) and (max-width: 1199px) {
  //     width: calc(100% - (100%/8));
  //   }

  //   @media(min-width: 576px) and (max-width: 767px) {
  //     width: calc(100% - (100%/6));
  //   }
  // }

  &_swiper {
    position: relative;

    &::before {
      background-color: $anti-flash-white1;
      bottom: 74px;
      content: '';
      height: rem(3);
      left: calc((100%/4) + #{rem(19)});
      position: absolute;
      width: calc(100% - (100%/2) - #{rem(39)});

      // NOTES: Medias depend on Breakpoints
      @media(min-width: 768px) {
        left: calc((100%/8) + #{rem(19)});
        width: calc(100% - (100%/4) - #{rem(39)});
      }

      @media(min-width: 576px) and (max-width: 767px) {
        left: calc(100%/6 + #{rem(19)});
        width: calc(100% - (100%/3) - #{rem(39)});
      }
    }
  }

  &_item {
    cursor: pointer;
    text-align: center;
  }

  &_inner {
    bottom: -8px;
    display: inline-block;
    padding: 34px 0;
    position: relative;
  }

  &_point {
    bottom: 76px;
    height: rem(8);
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: rem(8);
    z-index: $z-timline-point;

    #{$root-item}-active & {
      span {
        background: $spanish-green;
      }
    }

    span {
      background: $black-025;
      border-radius: 50%;
      display: block;
      height: rem(8);
      position: relative;
      width: rem(8);
    }

    &::after {
      background-color: $white;
      border-radius: 50%;
      content: '';
      height: rem(36);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: rem(36);
      z-index: -1;
    }

    &::before {
      background-color: $platinum106;
      border: rem(1) solid $anti-flash-white2;
      border-radius: 50%;
      content: '';
      height: rem(30);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: $animation;
      width: rem(30);

      #{$root-item}-active & {	
        background-color: $spanish-green01;
        border-color: $spanish-green;
      }
    }
  }

  &_year {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: rem(16);
    padding-right: rem(16);
    position: relative;
    transition: $animation;
    white-space: nowrap;

    @include desktop-up {
      padding-left: rem(24);
      padding-right: rem(24);
    }
	
    .a-text {
      color: $black-045;

      #{$root-item}-active & {
        color: $spanish-green;
        font-weight: 700;
      }
    }
  }

  &_navigation {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include desktop-up {
      display: block;
    }
  }

  &_next,
  &_prev {
    bottom: 62px;
    cursor: pointer;
    position: absolute;
    transition: opacity $animation;
    z-index: $z-normal;

    > .a-icon {
      height: rem(24);
      width: rem(13.26);
    }

    &.swiper-button-disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &.swiper-button-lock {
      display: none;
    }
  }

  &_prev {
    left: 0;
  }

  &_next {
    right: 0;
  }

  .swiper-container {
    padding-top: 20px;
  }
}
