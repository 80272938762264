.t-investmentList {
  &_filter {
    margin-left: auto;
    min-width: rem(100);
    width: fit-content;

    .m-pulldown {
      &_header {
        box-shadow: 0 0 33px rgba($black, 0.1);
      }
    }
  }
}
