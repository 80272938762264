.o-notify {
  $root: &;
  background-color: $white;
  border-radius: rem(4);
  box-shadow: 0 3px 6px -4px rgba($black, 0.12), 0 6px 16px rgba($black, 0.08), 0 9px 28px 8px rgba($black, 0.05);
  padding: rem(18) rem(16) rem(16) rem(24);
  position: relative;
  width: 100%;

  @include small-mobile {
    padding: rem(16);
  }

  &_btn-close {
    background-color: transparent;
    padding: 0;
    position: absolute;
    right: rem(16);
    top: rem(20);
    @include reset-button;
  }

  &_content {
    display: flex;
  }

  &_left {
    flex-shrink: 0;
    margin-right: rem(16);
  }

  &_right {
    flex-grow: 1;
  }

  &_head {
    display: flex;
    margin-bottom: rem(8);
    @include mobile-down {
      display: block;
    }
  }

  &_message {
    margin-top: rem(8);
  }

  &_btn {
    margin-top: rem(16);
    @include adjust-flex(flex-end, center);

    .a-button {
      height: rem(38);
      padding: 0 rem(8);
      @include font-base(14,22);
    }
  }
}
