.t-popupInfoEvent {
  &_content {
    display: flex;
    justify-content: space-between;
    padding: rem(16);

    @include small-mobile {
      display: block;
    }

    &_right {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      margin-left: rem(80);
      @include mobile-down {
        margin-left: rem(40);
      }
      @include small-mobile {
        margin-left: 0;
      }

      .a-button {
        border-radius: rem(4);
      }
    }
  }

  &_item {
    align-items: center;
    display: flex;
    margin-bottom: rem(30);

    @include small-mobile {
      margin-bottom: rem(16);
    }

    &:last-child {
      align-items: stretch;
      margin-bottom: 0;
      @include small-mobile {
        margin-bottom: rem(16);
      }
    }

    &_icon {
      flex-shrink: 0;
      height: 100%;
      margin-right: rem(8);
      width: rem(24);
    }

    &_subDesc {
      margin-top: rem(8);
    }
  }

}
