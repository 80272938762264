.m-playerCard {
  $root: &;
  
  &-isWatching {
    pointer-events: none;
  }

  &-horizontal {
    display: flex;
  }

  &_content {
    flex-shrink: 0;
    position: relative;

    #{$root}-normal & {
      width: rem(161);
      @include small-mobile {
        width: rem(150);
      }
    }

    #{$root}-small & {
      width: rem(112);
      @include small-mobile {
        width: rem(150);
      }
    }
  }

  &_description {
    color: $scorpion;
    flex-wrap: 1;

    .text {
      @include text-overflow(3);
    }

    #{$root}-normal & {
      padding: rem(10) 0 rem(10) rem(16);
      @include font-base(16,24);

      @include tablet-down {
        @include font-base(14, 22);
      }
    }

    #{$root}-small & {
      padding-left: rem(16);
      @include font-base(14,22);
      @include small-mobile {
        padding: rem(10) 0 rem(10) rem(16);
      }
    }

    #{$root}-large & {
      font-weight: 600;
      margin-top: rem(16);
      @include font-base(20,30);

      @include tablet-down {
        @include font-base(18, 27);
      }
  
      @include small-mobile {
        @include font-base(16, 24);
      }
    }
  }

  &_bg {
    cursor: pointer;
    display: block;
    
    #{$root}-isPlay & {
      opacity: 0;
      visibility: hidden;
    }
  }

  &_watching {
    background: linear-gradient(0deg, rgba($black, 0.8), rgba($black, 0.8));
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    @include adjust-flex;
  }

  &_play {
    background-color: $white;
    clip-path: polygon(0 0 , 100% 50% , 0 100%);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    #{$root}-normal & {
      height: rem(32);
      width: rem(25);
    }

    #{$root}-small & {
      height: rem(17);
      width: rem(13);

      @include small-mobile {
        height: rem(32);
        width: rem(25);
      }
    }

    #{$root}-large & {
      height: rem(63);
      width: rem(49);
      @include tablet-down {
        height: rem(47);
        width: rem(35);
      }
    }
  }

  &_video {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    #{$root}-isPlay & {
      opacity: 1;
      visibility: visible;
    }
  }
}
