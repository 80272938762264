.o-bannerPage {
  $root: &;
  position: relative;

  &_image {
    .a-image {
      &::after {
        background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0) 49.62%, rgba($black, 0.27) 68.67%, rgba($black, 0.27) 100%);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  &_title {
    bottom: rem(65);
    left: 50%;
    max-width: 70%;
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
  }

  &_menu {
    bottom: 0;
    left: 0;
    position: absolute;
    transform: translateY(50%);
    transition: $transition-medium ease-in-out;
    width: 100%;
    z-index: 10;

    @include tablet-down {
      position: initial;
      transform: translateY(0);
    }

    &.sticky {
      background-color: $snow;
      bottom: initial;
      box-shadow: 0 3px 6px -4px rgba($black, 0.12), 0 6px 16px rgba($black, 0.08), 0 9px 28px 8px rgba($black, 0.05);
      left: 0;
      position: fixed;
      top: rem($height-header-desktop-scroll);
      transform: translateY(0);

      @include tablet-down {
        top: rem($height-header-tablet-down);
      }

      #{$root}_item {
        margin-right: rem(35);

        &:last-child {
          margin-right: 0;
        }

        @include desktop-down {
          margin-right: rem(30);
        }

        @include tablet-down {
          margin-right: rem(16);
        }
      }

      #{$root}_list {
        background-color: $snow;
        box-shadow: initial;
        display: inline-flex;
        left: 50%;
        padding: rem(9) rem(33);
        position: relative;
        transform: translateX(-50%);

        @include tablet-down {
          padding: rem(9) rem(20);
        }
      }

    }
  }

  &_list {
    align-items: center;
    background-color: $white;
    border-radius: rem(10);
    box-shadow: 0 3px 6px -4px rgba($black, 0.12), 0 6px 16px rgba($black, 0.08), 0 9px 28px 8px rgba($black, 0.05);
    display: inline-flex;
    justify-content: space-between;
    left: 50%;
    list-style: none;
    max-width: 100%;
    overflow: auto;

    padding: rem(22) rem(40);
    position: relative;
    transform: translateX(-50%);

    &::-webkit-scrollbar {
      display: none;
    }

    @include desktop-down {
      padding: rem(16) rem(30);
    }

    @include tablet-down {
      padding: rem(10) rem(25);
    }

  }

  &_item {
    margin-right: rem(35);
    transition: $transition-medium linear;

    @include desktop-down {
      margin-right: rem(20);
    }

    @include tablet-down {
      margin-right: rem(16);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &_link {
    color: $taupe-gray1;
    cursor: pointer;
    text-transform: uppercase;
    transition: $transition-medium linear;
    white-space: nowrap;
    @include font-base(14, 22);

    &.active {
      color: $spanish-green;
      font-weight: bold;
    }

    &:hover {
      color: $spanish-green;
    }
  }
}
