.o-banner {
  position: relative;

  .swiper-pagination-fraction {
    pointer-events: none;
  }

  &_video {    
    video {
      object-fit: cover;
    }

    &_wrap {
      height: 100%;
      position: relative;
      width: 100%;
    }

    &_controls {
      bottom: rem(60);
      display: flex;
      position: absolute;
      right: rem(30);
      z-index: 3;
      @include tablet-down {
        bottom: rem(40);
        right: rem(10);
      }
    }

    &_play {
      margin-right: rem(15);
      opacity: 0;
      transition-delay: 0.5s;
      @include small-mobile {
        margin-right: rem(7);
      }
    }

    &_volume {
      opacity: 0;
      transition-delay: 0.6s;
    }
  }

  &_image {
    .a-image {
      &::after {
        background: $black-linear;
        content: '';
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }

  &_tag {
    bottom: rem(120);
    left: rem(-100);
    max-width: rem(520);
    opacity: 0;
    position: absolute;
    transition: opacity $transition ease-in-out , left 0.7s linear;
    transition-delay: 0.4s;
    z-index: 1;

    @include mobile-down {
      bottom: rem(35);
    }

    @include small-mobile {
      max-width: 85%;
    }

    &.active {
      left: rem(56);
      opacity: 1;
      @include small-mobile {
        left: rem(16);
      }
    }

    &_content {
      background: $black-04;
      border-left: rem(6) solid $green;
      padding: rem(18) rem(28);
  
      @include mobile-down {
        padding: rem(10) rem(18);
      }
    }
    
    &_title {
      .a-heading {
        @include mobile-down {
          @include font-base(16,20);
        }
      }
    
      @include text-overflow(2);
    }
  
    &_description {
      margin-top: rem(12);
      @include text-overflow(4);
    }
  }

  &_mouse {
    bottom: rem(24);
    cursor: pointer;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;

    @include mobile-down {
      display: none;
    }

    &_icon {
      animation: animateIconScroll 1.1s infinite;
      background: url('~assets/images/mouse.png') no-repeat center / auto;
      height: rem(34);
      margin: auto;
      margin-top: rem(8);
      width: rem(24);
    }
  }
}

@keyframes animateIconScroll {
  from {
    opacity: 0;
    transform: translateY(-60%);
  }

  to {
    opacity: 1;
    transform: translateY(20%);
  }
}
