.a-input {
  $root: &;

  &_label {
    display: block;
    font-weight: 400;
    margin-bottom: rem(8);
  }

  &_instance {
    appearance: none;
    border-radius: rem(2);
    font-family: inherit;
    margin: 0;
    outline: none;
    transition: all $transition linear;
    width: 100%;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  &-lg {
    #{$root}_label {
      @include font-base(14, 22);
    }

    #{$root}_instance {
      padding: rem(8) rem(12);
      @include font-base(16, 24);
    }
  }

  &-md {
    #{$root}_label {
      @include font-base(12, 16);
    }

    #{$root}_instance {
      padding: rem(5) rem(12);
      @include font-base(14, 22);
    }
  }

  &-sm {
    #{$root}_instance {
      border-radius: rem(4);
      padding: rem(12.5) rem(16);
      @include font-base(14, 20);
    }
  }

  &-primary {
    #{$root}_label {
      color: $white;
    }

    #{$root}_instance {
      background-color: $spanish-green2;
      border: 1px solid $spanish-green;
      color: $white;

      &::placeholder {
        color: $columbia-blue;
      }
    }
  }

  &-light {
    #{$root}_label {
      color: $davys-grey;
    }

    #{$root}_instance {
      background-color: $white;
      border: 1px solid $gainsboro;
      color: $black;

      &::placeholder {
        color: $taupe-gray1;
      }
    }
  }

  &-outline-green {
    #{$root}_instance {
      background-color: $white;
      border: 1px solid $spanish-green;
      color: $black;

      &::placeholder {
        color: $davys-grey;
      }
    }
  }

  &-error {
    #{$root}_instance {
      background-color: $white;
      border-color: $sinopia;
      color: $sinopia;

      &::placeholder {
        color: $sinopia;
      }
    }
  }

  &_message {
    margin-top: rem(4);
  }
}
