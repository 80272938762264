.o-popupPersonalIntro {
  display: flex;
  flex-wrap: wrap;
  padding: rem(32) rem(44) rem(41) rem(32);

  @include small-mobile {
    padding: rem(30);
  }

  &_thumbnail {
    position: relative;

    &::before {
      background-color: $spanish-green;
      border-radius: 50%;
      bottom: rem(-10);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }

  &_image {
    background-color: $isabelline;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    z-index: 2;
  }

  &_left {
    flex: 0 0 28%;

    @include small-mobile {
      flex: 0 0 100%;
    }
  }

  &_right {
    flex: 0 0 72%;
    padding-left: rem(44);

    @include mobile-down {
      padding-left: rem(25);
    }

    @include small-mobile {
      flex: 0 0 100%;
      margin-top: rem(20);
      padding-left: 0;
    }
  }

  &_name {
    @include small-mobile {
      margin-top: rem(16);
    }
  }

  &_position {
    margin-top: rem(4);
  }

  &_des {
    margin-top: rem(16);
    @include small-mobile {
      margin-top: rem(10);
    }
  }
}
