.t-videoTemplate {
  &_highlights {
    @include tablet-up {
      max-height: rem(390);
      overflow-y: auto;
      @include scroll-bars(12, $gray-x11, $white-smoke, 6, 3);

      &::-webkit-scrollbar-track {
        border-radius: rem(6);
      }
    }
  }

  &_divider {
    background-color: $gainsboro;
    height: rem(1);
  }
}
