.t-introSummary {
  $root: &;

  @include mobile-up {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 rem(-8);
  }

  @include tablet-up {
    margin: 0 rem(-16);
  }

  &_item {
    padding: 0 rem(16);
    
    @include mobile-down {
      margin-top: rem(-16);
    }

    @include mobile-up {
      flex: 0 0 calc(100% / 3);
      max-width: calc(100% / 3);
      padding: 0 rem(8);
    }

    @include tablet-up {
      padding: 0 rem(16);
    }
  }
}
