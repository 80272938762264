.a-text {
  $root: &;
  @include font-base(18, 27);

  @include tablet-down {
    @include font-base(16, 24);
  }

  @include mobile-down {
    @include font-base(14, 18);
  }

  &-10x16 {
    @include font-base(10, 16);
  }

  &-12x16 {
    @include font-base(12, 16);

    @include tablet-down {
      @include font-base(10, 14);
    }
  }

  &-12x18 {
    @include font-base(12, 18);
  }

  &-12x22 {
    @include font-base(12, 22);
  }

  &-13x28 {
    @include font-base(13, 28);
  }

  &-14x20 {
    @include font-base(14, 20);

    @include tablet-down {
      @include font-base(12, 16);
    }
  }

  &-14x21 {
    @include font-base(14, 21);
  }

  &-14x22 {
    @include font-base(14, 22);

    &#{$root}-md {
      @include tablet-down {
        @include font-base(12, 18);
      }
    }
  }

  &-14x24 {
    @include font-base(14, 24);

    &#{$root}-md {
      @include tablet-down {
        @include font-base(12, 20);
      }
    }
  }

  &-14x28 {
    @include font-base(14, 28);
    @include tablet-down {
      @include font-base(12, 20);
    }
  }

  &-14x32 {
    @include font-base(14, 32);
    @include tablet-down {
      @include font-base(12, 20);
    }
  }

  &-16x20 {
    @include font-base(16, 20);
  }

  &-16x22 {
    @include font-base(16, 22);

    @include tablet-down {
      @include font-base(14, 18);
    }
  }

  &-16x24 {
    @include font-base(16, 24);

    @include tablet-down {
      @include font-base(14, 20);
    }

    &#{$root}-md {
      @include mobile-down {
        @include font-base(12, 18);
      }
    }
  }

  &-16x32 {
    @include font-base(16, 32);

    @include tablet-down {
      @include font-base(14, 26);
    }
  }

  &-18x24 {
    @include font-base(18, 24);

    @include tablet-down {
      @include font-base(16, 22);
    }
  }

  &-18x27 {
    @include font-base(18, 27);

    @include tablet-down {
      @include font-base(16, 24);
    }
  }

  &-20x30 {
    @include font-base(20, 30);

    @include tablet-down {
      @include font-base(18, 27);
    }

    @include small-mobile {
      @include font-base(16, 24);
    }
  }

  &-20x24 {
    @include font-base(20, 40);

    @include tablet-down {
      @include font-base(18, 27);
    }

    @include small-mobile {
      @include font-base(16, 24);
    }
  }

  &-32x32 {
    @include font-base(32, 32);

    @include tablet-down {
      @include font-base(24, 28);
    }

    @include small-mobile {
      @include font-base(16, 20);
    }
  }

  &-36x36 {
    @include font-base(36, 36);

    @include desktop-down {
      @include font-base(28, 28);
    }

    @include tablet-down {
      @include font-base(24, 32);
    }

    @include small-mobile {
      @include font-base(20, 28);
    }
  }

  &-32x48 {
    @include font-base(32, 48);

    @include desktop-down {
      @include font-base(24, 32);
    }

    @include tablet-down {
      @include font-base(20, 30);
    }

    @include small-mobile {
      @include font-base(18, 24);
    }
  }

  &-24x32 {
    @include font-base(24, 32);

    @include tablet-down {
      @include font-base(20, 30);
    }

    @include mobile-down {
      @include font-base(18, 26);
    }
  }

  &-48x60 {
    @include font-base(48, 60);

    @include tablet-down {
      @include font-base(36, 48);
    }

    @include mobile-down {
      @include font-base(24, 36);
    }
  }

  @include style-color();
  @include style-font-weight();
  @include style-general();
}
