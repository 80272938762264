.m-cardInvest {
  $root: &;
  background-color: $white;
  box-shadow: 0 0 rem(33) rgba($black, 0.1);

  &:hover {
    #{$root}_thumbnail .a-image {
      transform: scale(1.25);
    }
  }

  &-sm {
    padding: rem(21) rem(32) rem(21) rem(16);
    @include small-mobile {
      padding: rem(16);
    }
  }

  &-lg {
    padding: rem(16) rem(24) rem(16) rem(16);
    @include small-mobile {
      padding: rem(16);
    }
  }

  &_flex {
    display: flex;
    flex-wrap: wrap;
    @include small-mobile {
      display: block;
    }
  }

  &_thumbnail {
    overflow: hidden;

    .a-image {
      transition: all $transition ease-in-out;
    }

    #{$root}-sm & {
      width: rem(165);
      @include small-mobile {
        width: 100%;
      }
    }

    #{$root}-lg & {
      width: rem(264);
      @include small-mobile {
        width: 100%;
      }
      @include mobile {
        width: rem(170);
      }
      @include desktop { 
        width: rem(170);
      }
    }

  }

  &_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    #{$root}-sm & {
      flex: 1;
      padding-left: rem(48);
      @include mobile-down {
        padding-left: rem(24);
      }
      @include small-mobile {
        padding: rem(16);
      }
    }

    #{$root}-lg & {
      flex: 1;
      padding-left: rem(40);
      @include desktop-down {
        padding-left: rem(24);
      }
      @include small-mobile {
        padding: rem(16);
      }
    }   
  }

  &_title {
    @include text-overflow(2);

    .a-heading {
      #{$root}-lg & {
        @include desktop { 
          @include font-base(16,24);
        }
      }
    }
  }

  &_description {
    @include text-overflow(2);

    .a-text {
      #{$root}-lg & {
        @include desktop { 
          @include font-base(14,20);
        }
      }
    }
  }

  &_time {
    align-items: center;
    display: flex;
    #{$root}-lg & {
      margin-top: rem(8);
    }
  }
}
