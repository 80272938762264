.o-accordion {
  $root: &;
  border: 1px solid $gainsboro;

  position: relative;
  transition: all $transition linear;

  &_wrapTitle {
    cursor: pointer;
    display: flex;
    padding-bottom: rem(12);
    padding-top: rem(12);
    transition: all $transition linear;
  }

  &_icon {
    padding-left: rem(16);
  }

  &_wrapper {
    overflow: hidden;
    transition: all $transition linear;
  }

  &_description {
    padding: rem(4) rem(40);

    @include mobile-down {
      padding-left: rem(26);
      padding-right: rem(26);
    }
  }

  &-active {
    border: 1px solid $spanish-green;

    #{$root}_wrapTitle {
      background-color: $spanish-green;
      box-shadow: 0 rem(-1) 0 $spanish-green;
    }
  }
}
