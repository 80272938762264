.m-thumbnail {
  $root: &;
  overflow: hidden;
  position: relative;

  &_image {
    position: relative;

    .a-image {
      transition: all $transition ease-in-out;
    }

    &::before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  &_text {
    bottom: rem(16);
    left: 50%;
    padding-left: rem(70);
    padding-right: rem(70);
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2;
    @include tablet-down {
      padding-left: rem(32);
      padding-right: rem(32);
    }
  }

  &-lg {
    #{$root}_text {
      bottom: rem(16);
    }
    #{$root}_image {
      &::before {
        background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0) 65.1%, rgba($black, 0.6) 82.29%, rgba($black, 0.8) 100%);
      }
    }
  }

  &-md {
    #{$root}_text {
      bottom: rem(8);
      padding-left: rem(24);
      padding-right: rem(24);
    }
    #{$root}_image {
      &::before {
        background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0) 65.07%, rgba($black, 0.5) 81.46%, rgba($black, 0.5) 100%);
      }
    }
  }

  &-layerBright {
    #{$root}_image {
      &::before {
        background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0) 71.35%, rgba($black, 0.3) 85.94%, rgba($black, 0.4) 100%);
      }
    }
  }

  &-scale {
    &:hover {
      #{$root}_image .a-image {
        transform: scale(1.25);
      }   
    }
  }

  &-radius {
    border-radius: rem(5);
    overflow: hidden;
  }
}
