.o-modal {
  $root: &;
  border: 0;
  color: $white;
  height: 100%;
  outline: 0;
  overflow: auto;
  position: fixed;
  width: 100%;

  &_main {
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    min-height: 100%;
    padding: rem(50) 0;

    @include tablet-down {
      padding: rem(30) rem(16);
    }
  }

  &_wrapper {
    background-color: $white;
    border-radius: rem(10);
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  &_close {
    background-color: $spanish-green;
    border-radius: 50%;
    padding: 0;
    position: absolute;
    right: rem(10);
    top: rem(10);
    z-index: 1;
    @include reset-button();
  }

  &_divider {
    background-color: $spanish-green;
    bottom: 0;
    height: rem(3);
    left: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: 1;
  }


  // Modifiers
  &-personal-intro {

    #{$root}_main {
      max-width: rem(768);
    }
  }

  &-notify {
    #{$root}_main {
      max-width: rem(384);
    }
  }

  &-utility {
    #{$root}_close {
      background-color: transparent;
      border-radius: 0;
      bottom: auto;
      left: rem(32);
      right: auto;
      top: rem(32);
    }

    #{$root}_wrapper {
      background-color: $black;
      border-radius: 0;
      position: initial;
    }

    #{$root}_main {
      margin: 0 auto;
      max-width: 100%;
      padding: rem(24) rem(50);

      @include tablet-down {
        padding: rem(70) rem(50);
      }

      @include small-mobile {
        padding: rem(30) rem(10);
      }
    }
  }

  &_portal {
    &-utility {
      .ReactModal__Overlay {
        background-color: $black !important;
      }
    }
  }
  
  &-jd {
    #{$root}_main {
      align-items: center;
      display: flex;
      justify-content: center;
      max-width: rem(768);
    }
    #{$root}_wrapper {
      align-items: center;
      border-radius: 0;
      box-shadow: 0 0 rem(24) $kombu-green-01;
      display: flex;
      justify-content: center;
      max-width: rem(740);
      
    }

    #{$root}_body {
      padding: 0;
      width: 100%;
    }

    #{$root}_close {
      background-color: transparent;
    }

    #{$root}_divider {
      bottom: auto;
      box-shadow: 0 0 rem(24) $kombu-green-01;
      height: rem(3);
      left: 0;
      top: 0;
    }
  }

  &-infoEvent {
    #{$root}_main {
      max-width: rem(820);
    }
    #{$root}_wrapper {
      border-radius: 0;
    }
  }

  &-infoEventRegister {
    #{$root}_main {
      max-width: rem(643);
    }
    #{$root}_wrapper {
      border-radius: 0;
    }
  }

}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;
}


.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}


.ReactModal__Overlay {
  background: rgba($black, 0.3) !important;
  inset: 0;
  opacity: 0;
  position: fixed;
  transform: translateY(-100%);
  transition: all 500ms ease-in-out;
  z-index: $z-modal;

  &::after {
    background: rgba(#0b0b0b, 0.6) !important;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &--open {
    overflow: hidden;

    @include tablet-up {
      padding-right: rem(7);
    }
  }

  &--after-open {
    opacity: 1;
    transform: translateY(0);
  }

  &--before-close {
    opacity: 0;
    transform: translateY(-100%);
  }
}


.reactmodal-html-open {
  overflow: hidden;
}
