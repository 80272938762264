.o-backToTop {
  background-color: $spanish-green;
  border-radius: 50%;
  bottom: rem(30);
  cursor: pointer;
  height: rem(60);
  opacity: 0;
  position: fixed;
  right: rem(30);
  transform: translateY(200%);
  transition: 0.3s linear;
  visibility: hidden;
  width: rem(60);
  z-index: $z-back-to-top;
  @include adjust-flex;

  @include desktop-down {
    height: rem(50);
    width: rem(50);
  }

  @include tablet-down {
    height: rem(40);
    right: rem(15);
    width: rem(40);
  }

  &:hover img {
    animation: run_arrow 0.7s linear infinite;
  }

  &.active {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes run_arrow {
  0% {
    transform: translateY(30%);
  }

  100% {
    opacity: 0.3;
    transform: translateY(-30%);
  }
}


