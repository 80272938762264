.t-agricultureQuality {
  $root: &;
  position: relative;

  &_layer {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    padding-bottom: calc((873 / 1366)*100%);
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    @include break-min (1367px) {
      padding-bottom: calc((500 / 1366)*100%);
    }
  }

  &_top {
    &_description {
      margin-top: rem(24);

      @include tablet-down {
        margin-top: rem(16);
      }
    }
  }

  &_bottom {
    margin-top: rem(80);

    @include tablet-down {
      margin-top: rem(60);
    }

    @include mobile-down {
      margin-top: rem(40);
    }
  }

  &_list {
    margin-top: rem(32);

    @include mobile-down {
      margin-top: rem(16);
    }

    @include small-mobile {
      margin-top: 0;
    }
  }

  &_row {
    display: flex;
    flex-wrap: wrap;

    &.top {
      #{$root}_col:last-child .t-agricultureItem {
        @include tablet-down {
          flex-direction: row-reverse;
        }
      }
    }

    &.bottom {
      #{$root}_col:first-child .t-agricultureItem {
        @include tablet-down {
          flex-direction: initial;
        }
      }
    }
  }

  &_col {
    flex: 0 0 50%;
    max-width: 50%;

    @include tablet-down {
      flex: 0 0 100%;
      max-width: 100%;
    }

    @include small-mobile {
      margin-top: rem(24);
    }
  }
}

.t-agricultureItem {
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  &-reverse {
    flex-direction: row-reverse;
  }

  &_left {
    box-shadow: 0 3px 6px -4px rgba($black, 0.12), 0 9px 28px 8px rgba($black, 0.05);
    filter: drop-shadow(0 6px 16px rgba($black, 0.08));
    flex: 0 0 50%;
    max-width: 50%;
    min-height: rem(358);

    @include small-mobile {
      flex: 0 0 100%;
      max-width: 100%;
      min-height: auto;
    }

    .a-image {
      height: 100%;

      @include small-mobile {
        &::before {
          padding-bottom: 100%;
        }
      }
    }
  }

  &_right {
    background-color: $snow;
    box-shadow: 0 3px 6px -4px rgba($black, 0.12), 0 6px 16px rgba($black, 0.08), 0 9px 28px 8px rgba($black, 0.05);
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;

    @include small-mobile {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &_abs {
    align-items: center;
    display: flex;
    height: 100%;
  }

  &_des {
    padding: rem(28) rem(32);
    @include scroll-bars(8);

    @include desktop-down {
      padding: rem(24);
    }

    @include mobile-down {
      padding: rem(16);
    }
  }
}
