.m-introInfo {
  $root: &;
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  &-right {
    flex-direction: row-reverse;
    #{$root}_wrapper_content {
      padding: 0 rem(52) 0 rem(130);

      @include desktop-down {
        padding: rem(30) rem(10);
      }

      @include small-mobile {
        padding: rem(16);
      }
    }
  }

  &-left {
    #{$root}_wrapper_content {
      padding: 0 rem(130) 0 rem(52);
      @include desktop-down {
        padding: rem(10) rem(30);
      }

      @include small-mobile {
        padding: rem(16);
      }
    }
  }

  &_image {
    flex: 0 0 50%;

    @include small-mobile {
      flex: 0 0 100%;
    }
  }

  &_wrapper {
    flex: 0 0 50%;
    position: relative;

    @include small-mobile {
      flex: 0 0 100%;
    }

    &_imgBg {
      bottom: 0;
      position: absolute;
      width: 100%;
      
      img {
        width: 100%;
      }
    }

    &_content {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      position: relative;
    }

    &_title {
      margin-bottom: rem(16);

      .a-heading {
        @include text-overflow(3);
        
        @include tablet-up {
          line-height: rem(72);
        }
      }

      @include tablet-down {
        margin-bottom: rem(16);
      }

      @include mobile-down {
        margin-bottom: rem(8);
      }
    }

    &_desc {
      ul {
        padding-left: rem(24);
        @include font-base(14, 28);
        @include tablet-down {
          @include font-base(12, 20);
        }
      }
    }
  }
}
