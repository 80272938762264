// Text Colors
$raisin-black: rgb(38, 38, 38); // #262626
$davys-grey: rgb(89, 89, 89); // #595959
$white-smoke: rgb(245, 245, 245); // #f5f5f5
$white: rgb(255, 255, 255); // #ffffff
$sonic-silver: rgb(117, 117, 117); //#757575
$spanish-green: rgb(0, 135, 67); //#008743
$quartz: rgb(75, 75, 75); //#4B4B4B
$outer-space: rgb(71, 71, 71); //#474747
$dark-liver: rgb(79, 79, 79); //#4F4F4F
$charleston-green: rgb(40, 40, 40); //#282828
$davys-grey2: rgb(86, 86, 86); //#565656
$arsenic: rgb(67, 67, 67); //#434343
$spanish-gray: rgb(158, 158, 158); //#9E9E9E
$black: rgb(0, 0, 0); //#000000
$eerie-black: rgb(30, 30, 30); // #1e1e1e
$eerie-black1: rgb(27, 27, 27); // #1b1b1b
$white-smoke1: rgb(244, 244, 244); //#F4F4F4
$ash-grey: rgb(182, 182, 182); //	#b6b6b6
$silver: rgb(193, 193, 193); //#c1c1c1
$amazon1: rgb(45, 120, 82); //#2d7852
$outer-space1: rgb(68, 68, 68); //#444444
$platinum: rgb(228, 228, 228); //#e4e4e4
$anti-flash-white: rgb(243, 243, 243); //#f3f3f3
$roman-silver: rgb(141, 144, 145); //#8d9091
$taupe-gray1: rgb(140, 140, 140); //#8c8c8c
$anti-flash-white1: rgb(240, 240, 240); //#f0f0f0
$anti-flash-white2: rgb(240, 242, 245); //#f0f2f5
$spanish-green1: rgb(0, 144, 70); //#009046
$pigment: rgb(237, 31, 33); //#ed1f21
$portland-orange: rgb(255, 77, 53); //#ff4d35
$fire-engine-red: rgb(215, 34, 34); //#d72222
$red-orange: rgb(255, 77, 79); // #ff4d4f
$gray-x11: rgb(191, 191, 191); // #bfbfbf
$scorpion: rgb(90, 90, 90); //#5A5A5A
// Palette Colors
$green-ryb: rgb(118, 168, 38); // #76a826
$dark-green: rgb(1, 58, 30); //#013a1e
$spanish-green2: rgb(10, 152, 81); // #0A9851;
$columbia-blue: rgb(204, 234, 219); // #CCEADB;

// Toast Colors
$toast-green: rgb(96, 168, 66); // #60a842
$sinopia: rgb(206, 49, 0); // #ce3100
$vivid-amber: rgb(206, 142, 0); // #ce8e00
$true-blue: rgb(0, 95, 206); // #005fce
$platinum: rgb(230, 230, 230); //#e6e6e6

// Others
$bg-white-smoke: rgb(246, 246, 246); // #f6f6f6
$kelly-green: rgb(41, 173, 4); // #29ad04
$gainsboro: rgb(217, 217, 217); //#d9d9d9
$sonic-silver12: rgb(112, 112, 112); // #707070
$green: rgb(96, 187, 70); //#60bb46
$bg-anti-flash-white: rgb(236, 246, 241); // #ecf6f1
$white2: rgb(253, 255, 253); //#fdfffd
$taupe-gray: rgb(143, 143, 143); //#8f8f8f
$dark-gray: rgb(169, 169, 169); //#a9a9a9
$platinum1: rgb(229, 229, 229); // #e5e5e5
$platinum6: rgb(229, 229, 229); // #e6e6e6
$isabelline: rgb(235, 236, 240); //#ebecf0
$snow: rgb(250, 250, 250); // #fafafa
$gray: rgb(191, 191, 191); //#BFBFBF;
$kombu-green: rgb(50, 65, 56); // #324138
$ghost-white: rgb(246, 247, 250); //#f6f7fa
$silver-chalice: rgb(168, 170, 174); //#a8aaae
$white-smoke2: rgb(244, 246, 250); //#f4f6fa

// Overlays
$black-025: rgba($black, 0.25);
$black-085: rgba($black, 0.85);
$black-09: rgba($black, 0.9);
$black-0085: rgba($black, 0.085);
$black-02: rgba($black, 0.2);
$black-012: rgba($black, 0.12);
$black-015: rgba($black, 0.15);
$black-005: rgba($black, 0.05);
$white-smoke1-015: rgba(244, 244, 244, 0.15);
$sonic-silver12-025: rgba($sonic-silver12, 0.25);
$silver-05: rgba($silver, 0.5);
$black-04: rgba($black, 0.4);
$white-05: rgba($white, 0.5);
$black-008: rgba($black, 0.08);
$black-035: rgba($black, 0.35);
$kombu-green-01: rgba($kombu-green, 0.1);
$kombu-green-03: rgba($kombu-green, 0.3);
$black-006: rgba($black, 0.006);
$spanish-green01: rgba($spanish-green, 0.1);
$platinum106: rgba($platinum1, 0.6);
$black-045: rgba($black, 0.45);
$white-0016: rgba($white, 0.016);
$white-06: rgba($white, 0.6);
$black-06: rgba($black, 0.6);
$black-01: rgba($black, 0.1);
//Linear Colors
$white-linear: linear-gradient(180deg, $white 0%, $white 81.25%);
$solutions-card-linear: linear-gradient(180deg,
rgba($black, 0) 66.25%,
rgba($black, 0.75) 100%);
$black-linear: linear-gradient(180deg,
rgba($black, 0) 0%,
rgba($black, 0) 73.41%,
rgba($black, 0.1) 88.27%,
rgba($black, 0.15) 100%);
$kelly-green-linear: linear-gradient(0deg,
rgba($kelly-green, 0.1),
rgba($kelly-green, 0.1));
$amazon1-linear: linear-gradient(180deg,
rgba($amazon1, 0.6) 0%,
rgba($dark-green, 0.6) 100%);
$outer-space1-linear: linear-gradient(0deg,
rgba($outer-space1, 0.4),
rgba($outer-space1, 0.4));
$white-linear1: linear-gradient(90deg,
$white -1.38%,
rgba($white, 0.817708) 62.51%,
rgba($white, 0) 100%);
$spanish-green-linear: linear-gradient(270deg,
$spanish-green 0%,
rgba($spanish-green, 0) 100%);
