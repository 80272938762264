.t-location {
  &_info-top {
    margin: 0 auto;
    max-width: rem(726);
  }

  .o-carousel {
    margin-top: rem(54);

    @include tablet-down {
      margin-top: rem(30);
    }

    &_prev {
      left: calc(66 / 1366 * 100%);
    }

    &_next {
      right: calc(66 / 1366 * 100%);
    }
  }

  &_item {
    position: relative;
  }

  &_tag {
    left: calc(130 / 1366 * 100%);
    max-width: rem(600);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: $transition-medium linear;
    z-index: 1;

    @include break-max(1366px) {
      max-width: rem(422);
    }

    @include small-mobile {
      max-width: 100%;
      position: initial;
      transform: translateY(0);
    }

    &_content {
      background: $black-04;
      border-left: rem(6) solid $green;
      padding: rem(24) rem(16) rem(24) rem(44);

      @include desktop-down {
        padding: rem(16);
      }
    }

    &_title {
      @include text-overflow(2);
    }

    &_info {
      display: flex;

      .a-icon {
        flex-shrink: 0;
        margin-right: rem(8);
      }

      .a-text {
        flex-grow: 1;
      }

      &.location {
        margin-top: rem(16);

        @include tablet-down {
          margin-top: rem(10);
        }

        .a-text {
          @include text-overflow(2);
        }
      }

      &.scale {
        margin-top: rem(13);

        @include tablet-down {
          margin-top: rem(7);
        }

        .a-text {
          @include text-overflow(1);
        }
      }
    }

    &_btn {
      display: flex;
      margin-top: rem(25);

      @include tablet-down {
        margin-top: rem(10);
      }

      .icon {
        margin-right: rem(16);
      }
    }
  }

}
