.a-loading {
  $root: &;
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  width: 60px;

  &_dots {
    background-color: $spanish-green;
    border-radius: 50%;
    height: rem(10);
    margin-left: rem(8);
    margin-right: rem(8);
    width: rem(10);

    &-1 {
      animation: pulse 0.4s ease 0s infinite alternate;
    }

    &-2 {
      animation: pulse 0.4s ease 0.2s infinite alternate;
    }

    &-3 {
      animation: pulse 0.4s ease 0.4s infinite alternate;
    }
  }

  &-full {
    background-color: $white-05;
    bottom: 0;
    height: 100vh;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: $z-loading;

    #{$root}_dots {
      height: rem(16);
      width: rem(16);
    }
  }
  
  &-block {
    margin-bottom: rem(200);
    margin-top: rem(300);
    width: 100%;
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0.25;
    transform: scale(0.75);
  }
}
