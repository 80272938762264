.t-annualReport {
  background-color: $spanish-green;
  padding: rem(60) 0;
  
  @include tablet-down {
    padding: rem(30) 0;
  }
  
  &_carousel {
    margin-top: rem(32);
    @include tablet-down {
      margin-top: rem(16);
    }
  }

}
