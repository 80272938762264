.o-footer {
  $root: &;
  $width-container-value: 1091;
  padding-top: rem(42);
  position: relative;
  @include tablet-down {
    padding-top: rem(32);
  }

  .o-container {
    position: relative;
  }

  &_background {
    background-image: url('~assets/images/footer/background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &_top {
    display: flex;
    flex-wrap: wrap;
  }

  // Logo
  &_logo {
    width: 100%;
    @include tablet-up {
      flex: 0 0 calc(100 / #{$width-container-value} * 100%);
    }

    &_thumbnail {
      max-width: rem(100);
      width: 100%;
    }
  }

  // Content
  &_content {
    width: 100%;
    @include tablet-up {
      flex: 0 0 calc(374 / #{$width-container-value} * 100%);
      margin-left: rem(32);
    }
    @include desktop-up {
      margin-left: rem(55);
    }
    @include tablet-down {
      flex: 0 0 40%;
      margin-top: rem(32);
    }
    @include mobile-down {
      flex: 0 0 100%;
    }

    &_list {
      list-style: none;
    }

    &_item {
      &:not(:first-of-type) {
        margin-top: rem(16);
        @include tablet-down {
          margin-top: rem(12);
        }
      }

      .a-text {
        p:not(:first-of-type) {
          margin-top: rem(16);
          @include tablet-down {
            margin-top: rem(12);
          }
        }
      }

      &.copyRight {
        @include mobile-down {
          display: none;
        }
      }
    }

    &_social {
      display: flex;
      flex-wrap: wrap;
      margin-left: rem(-12);
      margin-right: rem(12);

      &_item {
        flex: 0 0 rem(48);
        padding-left: rem(12);
        padding-right: rem(12);
      }
    }
  }

  &_menu {
    list-style: none;
    @include desktop-up {
      margin-left: rem(96);
    }
    @include tablet-up {
      margin-left: rem(52);
    }
    @include tablet-down {
      margin-left: rem(24);
      margin-top: rem(32);
    }
    @include mobile-down {
      // display: flex;
      // flex: 0 0 100%;
      // flex-wrap: wrap;
      // margin-left: rem(0);
      // margin-top: rem(6);
      display: none;
    }

    &_item {
      &:not(:first-of-type) {
        margin-top: rem(16);
      }

      @include mobile-down {
        flex: 0 0 50%;
        margin-top: rem(16);
      }
    }
  }

  &_members {
    flex-grow: 1;
    @include desktop-up {
      margin-left: rem(90);
    }

    @include tablet-up {
      margin-left: rem(52);
    }

    @include tablet-down {
      margin-left: rem(24);
      margin-top: rem(32);
    }

    @include mobile-down {
      flex: 0 0 100%;
      margin-left: rem(0);
      margin-top: rem(24);

      .m-pulldown {
        width: 60%;
      }
    }

    &_logo {
      width: rem(120);
      @include desktop-down {
        width: rem(78);
      }

      img {
        width: 100%;
      }
    }

    &_link {
      margin-right: rem(52);
      @include break-max(1365px) {
        margin-right: rem(32);
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &_option {
    background-color: $spanish-green;
    margin-top: rem(36);
    position: relative;

    @include mobile-down {
      margin-top: rem(16);
    }

    &_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      margin-left: rem(-25);
      margin-right: rem(-25);

      @include tablet-down {
        justify-content: flex-start;
        margin-left: 0;
        margin-right: 0;
      }
    }

    &_item {
      padding: rem(6) rem(25);

      @include tablet-down {
        flex: 0 0 calc(100% / 3);
        padding-left: 0;
        padding-right: 0;

        &:nth-child(3n-1) {
          text-align: center;
        }

        &:nth-child(3n) {
          text-align: right;
        }
      }

      @include small-mobile {
        flex: 0 0 50%;

        &:nth-child(3n-1) {
          text-align: left;
        }

        &:nth-child(3n) {
          text-align: left;
        }
      }
    }

    .pointer-event {
      cursor: pointer;
    }
  }

  &_copyRightMobile {
    padding: rem(7) 0;
    @include mobile-up {
      display: none;
    }
  }
}
