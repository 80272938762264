.a-heading {
  $root: &;
  margin: 0;

  &-h1 {

    &#{$root}-sm {
      @include font-base(24, 36);

      @include tablet-down {
        @include font-base(22, 30);
      }
  
      @include mobile-down {
        @include font-base(20, 30);
      }
    }

    &#{$root}-lg {
      @include font-base(64, 96);

      @include desktop-down {
        @include font-base(48, 60);
      }

      @include tablet-down {
        @include font-base(36, 48);
      }
  
      @include mobile-down {
        @include font-base(24, 36);
      }
    }
  }

  &-h2 {
    @include font-base(48, 70);

    @include tablet-down {
      @include font-base(36, 48);
    }

    @include mobile-down {
      @include font-base(24, 36);
    }

    &#{$root}-lg {
      @include font-base(85, 124);

      @include tablet-down {
        @include font-base(48, 70);
      }
  
      @include mobile-down {
        @include font-base(32, 40);
      }
    }
  
    &#{$root}-md {
      @include font-base(48, 60);

      @include tablet-down {
        @include font-base(36, 48);
      }
  
      @include mobile-down {
        @include font-base(24, 36);
      }
    }

    &#{$root}-xl {
      @include font-base(64, 96);

      @include desktop-down {
        @include font-base(48, 60);
      }

      @include tablet-down {
        @include font-base(36, 48);
      }
  
      @include mobile-down {
        @include font-base(24, 36);
      }
    }
  }

  &-h3 {
    @include font-base(36, 54);

    @include tablet-down {
      @include font-base(28, 36);
    }

    @include mobile-down {
      @include font-base(22, 30);
    }

    &#{$root}-md {
      @include font-base(40, 60);

      @include tablet-down {
        @include font-base(32, 48);
      }
  
      @include mobile-down {
        @include font-base(24, 36);
      }
    }
  }

  &-h4 {
    @include font-base(35, 51);

    @include tablet-down {
      @include font-base(28, 36);
    }

    @include mobile-down {
      @include font-base(22, 30);
    }

    &#{$root}-sm {
      @include font-base(32, 48);

      @include desktop-down {
        @include font-base(24, 36);
      }

      @include tablet-down {
        @include font-base(20, 30);
      }
    }
  }

  &-h5 {
    @include font-base(22, 30);

    @include tablet-down {
      @include font-base(20, 30);
    }

    &#{$root}-md {
      @include font-base(24, 32);

      @include tablet-down {
        @include font-base(22, 30);
      }
  
      @include mobile-down {
        @include font-base(20, 30);
      }
    }

    &#{$root}-lg {
      @include font-base(24, 36);

      @include tablet-down {
        @include font-base(22, 30);
      }
  
      @include mobile-down {
        @include font-base(20, 30);
      }
    }

    &#{$root}-xl {
      @include font-base(28, 42);

      @include tablet-down {
        @include font-base(24, 36);
      }
  
      @include mobile-down {
        @include font-base(22, 30);
      }
    }
  }

  &-h6 {
    @include font-base(20, 30);

    @include tablet-down {
      @include font-base(18, 27);
    }

    &#{$root}-sm {
      @include font-base(20, 24);
      @include tablet-down {
        @include font-base(18, 27);
      }
  
    }

    &#{$root}-md {
      @include font-base(20, 27);
      @include tablet-down {
        @include font-base(18, 27);
      }
    }
    
    &#{$root}-lg {
      @include font-base(20, 40);
      @include tablet-down {
        @include font-base(18, 27);
      }
    }
  }

  &-ls-md {
    letter-spacing: 0.06rem;
  }

  &-ls-lg {
    letter-spacing: 0.08rem;
  }

  @include style-font-weight();
  @include style-general();
  @include style-color();
}
