.t-libraryVideo {
  &_list {
    @include tablet-up {
      max-height: rem(392);
      overflow-y: auto;
      @include scroll-bars(12, $gray-x11, $white-smoke, 6, 3);

      &::-webkit-scrollbar-track {
        border-radius: rem(6);
      }
    }
  }

  .m-playerCard_description {
    color: $davys-grey;
  }
}
