.m-headingLayer {
  $root: &;
  padding-top: rem(60);
  position: relative;
  @include break-max(1365px) {
    padding-top: rem(26);
  }
  
  @include small-mobile {
    padding-top: 0;
  }
  
  &_shadow {
    bottom: rem(35);
    position: absolute;
    transform: translateX(-50%) scale(1.7);
    transition: $transition-medium linear;
    white-space: nowrap;
    width: fit-content;
    z-index: 1;

    @include small-mobile {
      display: none;
    }

    #{$root}-left & {
      left: 70%;
      transform: translateX(-50%) scale(1.7);
      @include max-desktop-down {
        transform: translateX(-50%) scale(1.45);
      }
      @include break-max(1365px) {
        bottom: rem(20);
        transform: translateX(-50%) scale(1.35);
      }
      @include tablet-down {
        transform: translateX(-50%) scale(1.2);
      }
      @include mobile-down {
        transform: translateX(-50%) scale(1.1);
      }
    }

    #{$root}-right & {
      right: 70%;
      transform: translateX(50%) scale(1.7);
      @include max-desktop-down {
        transform: translateX(50%) scale(1.45);
      }
      @include break-max(1365px) {
        bottom: rem(20);
        transform: translateX(50%) scale(1.35);
      }
      @include tablet-down {
        transform: translateX(50%) scale(1.2);
      }
      @include mobile-down {
        transform: translateX(50%) scale(1.1);
      }
    }
  }

  &_main {
    position: relative;
    z-index: 2;
  }
}
