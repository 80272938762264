.t-developmentHistory {
  $root: &;

  &_body {
    margin-top: rem(22);
  }

  &_wrapper {
    background: $white;
    box-shadow: 0 0 rem(24) rem(1) $kombu-green-01;
    position: relative;

    @include mobile-up {
      display: flex;
      flex-wrap: wrap;
    }
  }

  // &_title {
  //   margin-top: rem(8);
  // }

  &_decription {
    margin-top: rem(4);
  }

  &_left {
    @include mobile-up {
      flex: 0 0 calc(442 / 1126 * 100%);
    }
  }

  &_right {
    padding: rem(16) 0 rem(16) rem(16);

    @include mobile-up {
      flex: 1;
      padding: rem(32) 0 rem(32) rem(32);
    }
  }

  &_scrollbox {
    height: 100%;
    position: relative;

    @include mobile-up {
      padding-top: calc(261 / 1126 * 100%);
    }
  }

  &_inner {
    @include mobile-up {
      bottom: 0;
      display: flex;
      flex-direction: column;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &_content {
    flex: 1 1;
    padding-right: rem(16);

    @include mobile-up {
      overflow-y: auto;
      padding-right: rem(32);
      @include scroll-bars(3, $spanish-green, $white, 0, 0);
    }
  }

}
