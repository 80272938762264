.m-titleLine {
  display: flex;

  &::after,
  &::before {
    border-bottom: rem(2) solid;
    border-image-slice: 0 0 2 0;
    border-image-source: $spanish-green-linear;
    content: '';
    flex: 1 1;
    margin: auto;
    margin-bottom: rem(10);
  }

  &::before {
    margin-right: rem(24);

    @include mobile-down {
      margin-right: rem(15);
    }
  }

  &::after {
    margin-left: rem(24);
    transform: rotate(-180deg);

    @include mobile-down {
      margin-left: rem(15);
    }
  }
}
