.m-newsLabelGreen {
  background-color: $white;
  box-shadow: 0 3px 6px -4px rgba($black, 0.12), 0 6px 16px rgba($black, 0.08), 0 9px 28px 8px rgba($black, 0.05);

  &_time {
    background-color: $spanish-green;
    height: rem(72);
    width: rem(72);

    @include tablet-down {
      height: rem(60);
      width: rem(60);
    }
  }

  &_content {
    .a-text {
      @include text-overflow(2);
    }
  }
}

.m-newsLabelNormal {
  .day .a-text {
    @include small-mobile {
      @include font-base(16, 24);
    }
  }

  .content {
    @include text-overflow(1);
  }

  .download .a-button {
    height: auto;
    padding: 0;
  }

  .download .a-button_icon {
    margin: 0;
  }

  .download .a-icon {
    @include small-mobile {
      height: rem(20);
      width: rem(20);
    }
  }

  .download span:first-of-type {
    @include font-base(14, 22);
    font-weight: normal;
    margin-left: rem(8);

    @include tablet-down {
      margin-left: rem(4);
    }

    @include small-mobile {
      display: none;
    }
  }
}
