.t-projectDetail {
  @include mobile-down {
    min-height: calc(100vh - #{$height-header-tablet-down}px);
  }
}

.t-infoProjectDetail {
  &_sub {
    align-items: center;
    display: flex;
    margin-top: rem(8);

    &-separate {
      background-color: $taupe-gray1;
      height: rem(20);
      margin: 0 rem(32);
      width: 1px;
      @include small-mobile {
        margin: 0 rem(16);
      }
    }
  }

  &_gallery {
    display: grid;
    grid-gap: rem(16);
    grid-template-columns: repeat(4, 1fr);

    @include tablet-down {
      grid-gap: rem(10);
    }

    @include mobile-down {
      grid-template-columns: repeat(2, 1fr);
    }

    &-item {
      cursor: pointer;
      overflow: hidden;
      position: relative;

      .a-image {
        transition: $transition-long ease-in-out;
      }

      &:hover .a-image {
        transform: scale(1.1);
      }

      &:first-child {
        grid-column: 1/3;
        grid-row: 1/3;
        @include mobile-down {
          grid-row: auto;
        }
      }
    }

    &-count {
      background-color: $black-06;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      @include adjust-flex;
    }
  }

  &_detail {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(30);

    @include tablet-down {
      margin: rem(20) rem(-10) 0;
    }
  }

  &_block {
    margin: rem(10) rem(100) 0 0;
    max-width: 30%;

    @include break-max(1365px) {
      margin-right: rem(50);
    }

    @include desktop-down {
      margin-right: rem(30);
    }

    @include tablet-down {
      flex: 0 0 50%;
      margin-right: 0;
      max-width: 50%;
      padding: 0 rem(10);
    }

    &:last-child {
      margin-right: 0;
    }

    &-content {
      margin-top: rem(4);

      .a-heading {
        @include small-mobile {
          @include font-base(16,24); }
      }
    }
  }

  &_content {
    border-bottom: 1px solid $gainsboro;
    border-top: 1px solid $gainsboro;
    margin-top: rem(40);
    padding: rem(16) 0 rem(32);
    
    @include tablet-down {
      margin-top: rem(30);
      padding: rem(16) 0;
    }
  }
}
