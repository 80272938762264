.p-aboutUs {
  @include mobile-down {
    min-height: calc(100vh - #{$height-header-tablet-down}px);
  }
  
  &_summary {
    margin-top: rem(24);
    @include tablet-down {
      margin-top: rem(0);
    }
  }

  &_history {
    margin-top: rem(114);
    @include tablet-down {
      margin-top: rem(60);
    }
  }

  &_partners {
    margin-bottom: rem(72);
    margin-top: rem(100);
    @include tablet-down {
      margin-bottom: rem(48);
      margin-top: rem(60);
    }
  }
}
