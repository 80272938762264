.m-projectCard {
  $root: &;
  overflow: hidden;
  position: relative;

  &_thumbnail {
    position: relative;

    &::before {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      content: '';
      height: 0;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all $transition-long ease-in-out;
      width: 0;
      z-index: 1;
    }

  }

  &_content {
    bottom: rem(38);
    left: 50%;
    padding-left: rem(16);
    padding-right: rem(16);
    position: absolute;
    transform: translate(-50%, rem(86));
    transition: all $transition-long ease-in-out;
    width: 100%;
    z-index: 2;

    @include tablet-down {
      padding-left: rem(8);
      padding-right: rem(8);
    }
  }

  &_flex {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(24);
    opacity: 0;
    transition: all $transition ease-in-out;
    visibility: hidden;

    @include tablet-down {
      margin-top: rem(12);
    }
  }

  &_item {
    flex: 0 0 25%;

    &:nth-child(2) {
      flex: 0 0 50%;
    }
  }

  &:hover {
    #{$root}_thumbnail::before {
      height: 100%;
      width: 100%;
    }

    #{$root}_content {
      transform: translate(-50%, 0);
    }

    #{$root}_flex {
      opacity: 1;
      visibility: visible;
    }
  }
}
