.m-jobEnvironment {
  $root: &;
  align-items: center;

  display: flex;

  @include mobile-down {
    display: block;
  }

  &_thumbnail {
    flex: calc(632 / 1091 * 100%);

    @include mobile-down {
      flex: 0 0 100%;
    }
  }

  &_content {
    flex: calc(459 / 1091 * 100%);
    padding-left: rem(60);

    @include desktop-down {
      padding-left: rem(32);
    }

    @include tablet-down {
      padding-left: rem(16);
    }

    @include mobile-down {
      flex: 0 0 100%;
      margin-top: rem(16);
      padding-left: 0;
    }
  }

  &_icon {
    max-width: rem(80);

    @include desktop-down {
      max-width: rem(60);
    }

    @include tablet-down {
      max-width: rem(48);
    }

  }

  &-reverse {
    flex-direction: row-reverse;

    #{$root}_content {
      padding-left: 0;
      padding-right: rem(60);

      @include desktop-down {
        padding-right: rem(32);
      }

      @include tablet-down {
        padding-right: rem(16);
      }

      @include mobile-down {
        padding-right: 0;
      }
    }
  }

  .description {
    @include text-overflow(4);
  }
}
