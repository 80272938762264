.t-introPartners {
  &_carousel {
    margin-top: rem(40);

    @include mobile-down {
      margin-top: rem(20);
    }

    .o-carousel {
      .swiper-container {
        margin: rem(-10);
      }

      .swiper-slide {
        padding: rem(10);
      }
    }
  }

  &_inner {
    border-radius: rem(5);
  }

}
