.t-partners {
  padding-bottom: rem(72);
  padding-top: rem(52);

  @include tablet-down {
    padding-bottom: rem(54);
    padding-top: rem(32);
  }

  @include mobile-down {
    padding-bottom: rem(32);
    padding-top: rem(16);
  }

  &_carousel {
    margin-top: rem(32);
    @include mobile-down {
      margin-top: rem(15);
    }
  }
}
