.t-error {
  $root: &;
  background-color: $snow;
  min-height: 70vh;

  &_thumbnail {
    max-width: rem(450);
    @include desktop-down {
      max-width: rem(400);
    }
    @include mobile-down {
      max-width: rem(320);
    }
  }

  &_content {
    @include tablet-down {
      text-align: center;
    }
  }

  .a-button span {
    font-weight: 400;
    @include font-base(14, 22);
  }
}
