.t-outstandingProject {
  background-image: url('~assets/images/outstandingProject/background.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: rem(100);
  padding-top: rem(100);

  @include tablet-down {
    padding-bottom: rem(40);
    padding-top: rem(40);
  }

  @include mobile-down {
    background-image: url('~assets/images/outstandingProject/background_mobile.png');
  }

  .o-container {
    @include break-min(1366px) {
      padding-left: rem(115);
      padding-right: rem(115);
    }
  }

  .swiper-slide {
    margin: auto;
    margin-left: rem(10);
    margin-right: rem(10);
    max-width: rem(374); // 314 + 60 (margin-x of active, prev, next)

    @include tablet-down {
      max-width: rem(340);
    }

    @include mobile-down {
      max-width: rem(400);
    }

    &-active {
      max-width: rem(470);
      transform-origin: center;

      @include tablet-down {
        max-width: rem(400);
      }
    }

    &-next:not(.swiper-slide-active),
    &-prev:not(.swiper-slide-active) {
      max-width: rem(314);

      @include tablet-down {
        max-width: rem(280);
      }

      @include mobile-down {
        max-width: rem(400);
      }

      &::before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;

        @include mobile-down {
          content: none;
        }
      }

      .m-projectCard {
        pointer-events: none;
      }

      .m-projectCard_content {
        @include mobile-up {
          display: none;
        }
      }
    }

    &-prev:not(.swiper-slide-active)::before {
      background: linear-gradient(91.01deg, #fff 0.9%, rgba(255, 255, 255, 0.7602) 49.79%, rgba(255, 255, 255, 0) 99.34%);
    }

    &-next:not(.swiper-slide-active)::before {
      background: linear-gradient(269.67deg, #fff 0.32%, rgba(255, 255, 255, 0.736337) 47.43%, rgba(255, 255, 255, 0) 101.73%);
    }
  }

  .o-carousel_prev {
    left: rem(-16);

    @include desktop-down {
      left: rem(-32);
    }

    @include mobile-down {
      display: none;
    }
  }

  .o-carousel_next {
    right: rem(-16);

    @include desktop-down {
      right: rem(-32);
    }

    @include mobile-down {
      display: none;
    }
  }
}
