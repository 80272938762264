.m-newsCard {
  $root: &;

  &_thumbnail {
    overflow: hidden;

    .a-image {
      transition: all $transition ease-in-out;
    }
  }

  &_title {
    @include text-overflow(3);
  }

  &-horizontal {
    background-color: $white;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    #{$root}_flex {
      display: flex;

      @include small-mobile {
        display: block;
      }
    }
    #{$root}_thumbnail {
      flex-shrink: 0;
      max-width: rem(197);
      width: 100%;
      @include tablet-down {
        max-width: rem(138);
      }
      @include small-mobile {
        max-width: 100%;
      }
    }
    #{$root}_content {
      padding: rem(8) rem(16);
      @include small-mobile {
        padding: rem(16);
      }
    }
  }

  &:hover {
    #{$root}_thumbnail .a-image {
      transform: scale(1.25);
    }
  }
}
