/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------- Attention seekers ----------------------------------- */
/* ----------------------------------------------------------------------------------------- */

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
/* ----------------------------------------------------------------------------------------- */
/* ---------------------------------- Bouncing entrances ----------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0) scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0) scaleY(0.9);
  }

  75% {
    transform: translate3d(0, -10px, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, 5px, 0) scaleY(0.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------- Fading entrances ------------------------------------ */
/* ----------------------------------------------------------------------------------------- */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------- Bounce hover card ---------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@keyframes bounceY {
  0% {
    transform: translateY(-205px);
  }

  40% {
    transform: translateY(-100px);
  }

  65% {
    transform: translateY(-52px);
  }

  82% {
    transform: translateY(-25px);
  }

  92% {
    transform: translateY(-12px);
  }

  55%,
  75%,
  87%,
  97%,
  100% {
    transform: translateY(0);
  }
}
