.t-rules {
  background-color: $white;
  box-shadow: 0 3px 6px -4px rgba($black, 0.12), 0 6px 16px rgba($black, 0.08), 0 9px 28px 8px rgba($black, 0.05);
  padding: rem(60) 0 rem(52);

  @include tablet-down {
    padding: rem(40) 0;
  }

  &_item {
    border-bottom: 1px solid rgba($black, 0.06);
    padding: rem(16);
    
    @include small-mobile {
      padding: rem(8);
    }
  }
}
