.t-contactForm {
  &_form {
    background-color: $spanish-green;
    border-radius: rem(10);
    padding: rem(48) rem(52) rem(40);

    @include small-mobile {
      padding: rem(24) rem(16);
    }
  }

  // &_submit {
  //   displ
  // }
}
