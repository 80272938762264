.o-header {
  $root: &;
  background-color: $white;
  box-shadow: 0 0 30px $black-015;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-header;

  &_wrap {
    align-items: center;
    display: flex;
    height: rem($height-header-desktop);
    transition: $transition-medium ease-in-out;
    @include tablet-down {
      height: rem($height-header-tablet-down);
      justify-content: space-between;
    }
  }

  &_left {
    flex-shrink: 0;
  }

  &_right {
    flex-grow: 1;

    @include tablet-down {
      align-items: center;
      display: flex;
      flex-grow: initial;
    }
  }

  &_search {
    align-items: center;
    background-color: $black-09;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: $transition-long linear;
    z-index: 4;

    &.open {
      transform: translateX(0); 
    }

    .button-close {
      @include reset-button;
      background-color: transparent !important;
      padding: 0;
      position: absolute;
      right: rem(32);
      top: rem(32);
    }

    &-content {
      max-width: rem(600);
      width: 100%;
      @include mobile-down {
        max-width: 80%;
      }
    }

    &-input {
      margin-top: rem(24);
    }
  }

  &_sub {
    transition: $transition-medium ease-in-out;
  }

  &_main {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: rem(18);
    transition: $transition-long linear;

    @include tablet-down {
      background-color: $anti-flash-white;
      display: block;
      height: 100vh;
      margin-top: 0;
      overflow: auto;
      padding: rem(14.5) rem(104) rem(50) rem(104);
      position: fixed;
      right: 0;
      top: 0;
      transform: translateX(-100%);
      transition: $transition-long linear;
      width: 100%;
      z-index: 3;
    }

    @include small-mobile {
      padding: rem(14.5) rem(16) rem(50) rem(16);
    }
    
    #{$root}-open & {
      @include tablet-down {
        transform: translateX(0);
      }
    }

    .utilities {
      @include tablet-down {
        display: none;
      }
    }
  }

  &_hamburger {
    min-width: rem(52);
    @include tablet-up {
      display: none;
    }
  }

  &_hamburger-search {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include tablet-up {
      display: none;
    }
  }

  &_main-social {
    margin-top: rem(40);
    @include tablet-up {
      display: none;
    }
  }

  &_main-search {
    margin-top: rem(20);
    @include tablet-up {
      display: none;
    }
  }

  &-scroll {
    @include tablet-up {
      #{$root}_wrap {
        height: rem($height-header-desktop-scroll);
      }
      #{$root}_sub {
        height: 0;
        overflow: hidden;
      }
      #{$root}_main {
        margin-top: 0;
      }

      .logo_main {
        width: rem(52);
      }

      .logo_sub {
        width: rem(44);
      }
    }
  }
}

.logo {
  align-items: center;
  display: flex;

  &_main {
    transition: $transition-medium ease-in-out;
    width: rem(72);
    @include tablet-down {
      width: rem(56);
    }
  }

  &_sub {
    transition: $transition-medium ease-in-out;
    width: rem(53);
  }

  &_separate {
    background-color: $gainsboro;
    height: rem(24);
    margin: 0 rem(20);
    width: 1px;
    @include desktop-down {
      margin: 0 rem(16);
    }
  }
}

.utilities {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  .social {
    @include tablet-down {
      display: none;
    }
  }

  &_search {
    align-items: center;
    display: flex;
    margin-left: rem(24);
    @include desktop-down {
      margin-left: rem(16);
    }
    @include tablet-down {
      display: none;
    }
  }

  &_separate {
    background-color: $gainsboro;
    height: rem(24);
    margin: 0 rem(24);
    width: 1px;

    &.none {
      display: none;
    }
    @include desktop-down {
      margin: 0 rem(16);
    }
    @include tablet-down {
      display: none;
    }
  }
}

.social {
  align-items: center;
  display: flex;
  list-style: none;

  &_item {
    margin-right: rem(20);
    width: rem(24);

    &:last-child {
      margin-right: 0;
    }
  }
}

.menu {
  $root: &;
  align-items: center;
  display: flex;
  list-style: none;

  @include tablet-down {
    display: block;
  }

  &_item {
    margin-right: rem(22);

    @include desktop-down {
      margin-right: rem(16);
    }

    @include tablet-down {
      margin-right: 0;
      margin-top: rem(20);
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      > #{$root}_link {
        color: $spanish-green;
        font-weight: 600;
      }
    }
  }

  &_link {
    @include font-base(14,22);
    color: $charleston-green;
    transition: $transition-medium linear;

    &.active {
      color: $spanish-green;
      font-weight: 600;
    }

    #{$root}_item:hover > & {
      color: $spanish-green;
    }
  }

  &.child {
    margin-left: rem(30);

    @include tablet-up {
      display: none;
    }

    #{$root}_item {
      list-style: circle;
      position: relative;
    }
  }
}

.hamburger {
  background-color: $spanish-green;
  border: 0;
  border-radius: rem(12);
  padding: rem(8);
}

.search_button {
  @include reset-button;
  background-color: transparent !important;
  padding: 0;
}

.language {
  $root: &;
  @include adjust-flex;
  background-color: $white-smoke2;
  position: relative;

  &_item {
    cursor: pointer;
    height: rem(22);
    position: relative;
    width: rem(26);
    z-index: 2;
    @include adjust-flex;
    
    span {
      @include font-base(14,18);
      color: $silver-chalice;
      font-weight: 600;
      transition: $transition-long linear;
    }
  }

  &::before {
    background-color: $spanish-green;
    content: '';
    height: rem(22);
    left: 0;
    position: absolute;
    top: 0;
    transition: $transition-long linear;
    width: rem(26);
    z-index: 1;
  }

  &-left {
    &::before {
      left: 0;
    }
    #{$root}_item:first-child {
      span {
        color: $white;
      }
    }
  }

  &-right {
    &::before {
      left: 50%;
    }
    #{$root}_item:last-child {
      span {
        color: $white;
      }
    }
  }
 
}


.a-input-search {
  position: relative;
  $root: &;

  &_input {
    background-color: $white;
    border: 0;
    border: rem(1) solid $gainsboro;
    border-radius: rem(100);
    color: $raisin-black;
    height: rem(58);
    outline: 0;
    padding: 0 rem(24);
    width: 100%;
    @include font-base(20, 30);

    &::placeholder {
      color: $black-025;
    }

    @include tablet-down {
      border-radius: rem(20);
      height: rem(44);
      @include font-base(16, 24);
    }
  }
}
