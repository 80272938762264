.t-popupInfoEventRegister {
  padding: rem(48);
  @include small-mobile {
    padding: rem(35) rem(16);
  }

  .a-button {
    border-radius: rem(4);
  }
}
