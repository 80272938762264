.p-leaderShip {
  &_content {
    margin-bottom: rem(100);
    margin-top: rem(133);
    @include tablet-down {
      margin-bottom: rem(48);
      margin-top: rem(63);
    }
  }
}
