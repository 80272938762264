.o-carousel {
  $root: &;
  position: relative;

  &_content {
    position: relative;
  }

  &_pagination {
    text-align: center;
    
    .swiper-pagination-bullet {
    
      height: rem(12);
      margin: 0 rem(8);
      opacity: 0.6;
      transition: 0.2s transform,0.2s left;
      width: rem(12);

      #{$root}-pagination-black & {
        background-color: $arsenic;
      }

      #{$root}-pagination-white & {
        background-color: $white;
      }

      &-active-main {
        height: rem(12);
        opacity: 1;
        width: rem(12);
        
        #{$root}-pagination-black & {
          background-color: $arsenic;
        }

        #{$root}-pagination-white & {
          background-color: $white;
        }

      }
    }
  }

  .swiper-pagination-bullets-dynamic {
    margin: rem(25) auto 0;
    padding: 0 rem(8);
    white-space: nowrap;
  
    &.swiper-pagination-lock {
      display: none;
    }
  }

  .swiper-pagination-fraction {
    bottom: rem(24);
    color: $white-05;
    display: flex;
    font-weight: 400;
    justify-content: flex-end;
    letter-spacing: 0.1em;
    padding-right: rem(12);
    position: absolute;
    z-index: 1;
    @include font-base(32, 32);

    @include mobile-up {
      padding-right: rem(56);
    }

    @include tablet-down {
      @include font-base(24, 28);
    }

    @include mobile-down {
      bottom: rem(10);
    }

    @include small-mobile {
      @include font-base(16, 20);
    }

    .swiper-pagination-current {
      color: $white;
    }
  }

  &_prev,
  &_next {
    appearance: none;
    cursor: pointer;
    outline: none;
    position: absolute;
    transition: opacity 0.3s ease-in-out;
    z-index: 2;

    #{$root}-navigationOutside &,
    #{$root}-navigationInside & {
      top: 50%;
      transform: translateY(-50%);
    }

    #{$root}-useBanner & {
      bottom: rem(24);
      @include tablet-down {
        bottom: rem(7);
      }
    }

    #{$root}-hideNavigationTabletDown & {
      @include tablet-down {
        display: none;
      }
    }

    #{$root}-hideNavigationSmallMobileDown & {
      @include small-mobile {
        display: none;
      }
    }

    #{$root}-hideAlways & {
      display: none;
    }

    &:hover {
      opacity: 0.7;
    }

    &.swiper-button-disabled {
      cursor: unset;
      opacity: 0.5;
      pointer-events: none;
    }

    .a-icon {
      @include mobile-down {
        height: rem(24);
        width: rem(24);
      }
    }

    &.swiper-button-lock {
      display: none;
    }
  }

  &_prev {
    #{$root}-navigationOutside & {
      left: rem(-66);
      @include desktop-down {
        left: rem(-50);
      }
    }
    #{$root}-navigationInside & {
      left: calc(123 / 1366 * 100%);
      @include break-min(1366px) {
        left: rem(123);
      }
    }
    #{$root}-useBanner & {
      left: rem(56);
      @include mobile-down {
        left: rem(12);
      }
    }
  }

  &_next {
    #{$root}-navigationOutside & {
      right: rem(-66);
      @include desktop-down {
        right: rem(-50);
      }
    }
    #{$root}-navigationInside & {
      right: calc(123 / 1366 * 100%);
      @include break-min(1366px) {
        right: rem(123);
      }
    }
    #{$root}-useBanner & {
      left: rem(104);
      @include mobile-down {
        left: rem(54);
      }
    }
  }

  &_content {
    height: 100%;
  }

}
